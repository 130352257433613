.dealers {
  margin: 8rem 0 0px;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
  font-family: "Montserrat", "Open Sans", sans-serif;
}

.map {
  margin-top: 20px;
}

.map img {
  width: 300px;
}

.dealers-sort .card {
  -webkit-box-shadow: 0px 1px 11px -1px #bababb;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.34);
}

.dealers-sort .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dealers-sort-inputs {
  display: flex;
  align-items: center;
  padding: 1.4rem 0px;
}

.dealers-sort-input {
  margin-right: 40px;
}

.dealers-sort .dealer-sort-btn {
  background-color: #df4e24;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  text-align: left;
  color: #fff;
  padding: 15px 30px;
  border-radius: 20px;
}

.dealers-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 20vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}

.dealers-header .wrapper {
  min-height: 20vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}

.dealers-header .wrapper .text-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}

.dealers-header .wrapper .text-wrapper .subtitle {
  font-size: 15px;
  color: rgb(223, 223, 223);
}

.dealers-cards-wrapper,
.dealers-sort {
  width: 80%;
  margin: 0 auto;
}

.dealers-cards-title {
  text-align: start;
  margin: 30px 0px;
  color: #00425d;
  padding-bottom: 40px;
  border-bottom: #00425d solid 2px;
}

.dealers-cards-title h5 {
  font-weight: bolder;
  font-size: 1.4rem;
}

.dealers-card {
  border-bottom: #ccc 1px solid;
  padding: 30px 0px;
}

.dealers-card ul {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  text-align: start;
}

.dealers-card ul li {
  list-style: none;
  width: 20%;
}

.dealers-card ul li img {
  width: 100px;
}

.dealers-card .dealers-number {
  display: flex;
  align-items: center;
}

.dealers-card .dealers-number svg {
  color: #00425d;
  font-size: 1.4rem;
  margin-right: 10px;
}

.dealers-card .dealers-number div.number {
  font-weight: bold;
  font-size: 1.3rem;
}

.dealers-card .dealers-number p {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 13px;
  margin-top: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #636363;
}

.dealers-card .dealers-card-contact-number {
  display: flex;
  justify-content: start;
  text-align: start;
}

.dealers-card .dealers-card-contact-number div:first-child {
  font-weight: bold;
}

.dealers-card .dealers-card-contact-number div span {
  color: #000;
  /* font-weight: bold; */
  font-size: 14px;
}

.dealers-card .dealers-card-contact-number div span.number {
  margin-right: 15px;
  color: #153e4d;
}

.dealers-card .dealers-card-contact-number span.show-number {
  margin-left: 0px;
  font-size: 10px;
  color: #000;
}

.dealers-card .dealers-card-location {
  display: flex;
  align-items: center;
}

.dealers-card .dealers-card-location .dealers-card-location-icon {
  font-size: 1.5rem;
  color: #00425d;
}

.dealers-card .dealers-card-location-details {
  margin-right: 30px;
  width: 60%;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #191919;
}

.dealers-card .see-map {
  width: 40%;
}

.dealers-card .see-map a {
  color: #000;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .dealers-card .see-map span {
  margin-right: 10px;
} */

.dealers-review #review {
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

#review {
  display: flex;
  align-items: center;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #636363;
}

#review div {
  margin: 0px 5px;
}

/* DEALER PAGE */
.dealer-page {
  margin: 10rem 0 0;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
}

.dealer-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.dealer-page-header .dealer-name {
  text-align: start;
}

.dealer-page-header .dealer-logo img {
  width: 100px;
  height: 50px;
  padding: 5px;
  border: 1px solid #ccc;
}

.dealer-page-details {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.dealer-page-details-logo {
  display: flex;
  width: 35%;
  margin-right: 40px;
}

.dealer-page-details-logo img {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
}

.dealer-page-details-items {
  width: 65%;
}

.dealer-page-details-item {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
}

.dealer-page-details-item .logo {
  margin-right: 15px;
}

.dealer-page-details-item .text h6 {
  text-align: start;
}

.dealer-page-details-item .text p {
  font-size: 12px;
  text-align: start;
}

.dealer-page-details-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.website {
  color: #153e4d;
  cursor: pointer;
}

.dealer-page-details-contact .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dealer-page-details-contact .icons .icon {
  background-color: #153e4d;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;
  width: 30px;
}

.dealer-page-details-contact .icons .icon svg {
  color: #fff;
}

.dealer-page-nav-panel {
  margin-top: 50px;
}

.dealer-page-nav-panel .dealer-panel-nav {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #00425d;
  color: #fff;
  font-size: 14px;
}

.dealer-page-nav-panel .dealer-panel-nav div {
  margin-right: 20px;
}

.dealer-page-nav-panel .dealer-panel-nav div span {
  margin-right: 10px;
}

.dealer-panel-selected {
  background-color: #fff;
  color: #00425d;
  height: 100%;
  padding: 12px;
  border-top: 2px solid #00425d;
}

.dealer-page-nav-panel-stock-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dealer-page-nav-panel-stock-header ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealer-page-nav-panel-stock-header ul li {
  list-style: none;
  cursor: pointer;
  padding: 2px;
}

.dealer-page-nav-panel-stock-header ul li:first-child {
  font-size: 13px;
  display: flex;
  align-items: center;
}

.dealer-page-nav-panel-stock-header ul li svg {
  font-size: 30px;
}

.dealer-page-nav-panel-stock-header ul li svg:last-child {
  /* background-color: #f2f3f3; */
  padding: 8px;
}

.dealer-page-nav-panel-cards .row {
  border-bottom: 1px solid #ccc;
  padding-bottom: 40px;
}

.dealer-page-nav-panel-card .panel-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dealer-page-nav-panel-card .panel-buttons button {
  padding: 5px 0px;
  border-radius: 5px;
}

.dealer-page-nav-panel-card .panel-buttons .panel-price-btn {
  width: 39%;
  font-size: 12px;
  position: relative;
  background-color: rgb(7, 204, 204);
  border: solid 1px rgb(7, 204, 204);
}

.dealer-page-nav-panel-card .panel-buttons h6 {
  width: 59%;
  font-size: 14px;
}

.dealer-page-nav-panel-card .options {
  margin: 0;
  padding: 0;
}

.dealer-page-nav-panel-card .icon-options div {
  width: 50%;
  display: inline-block;
  text-align: start;
  margin: 0 auto;
  padding: 1px;
  color: #133440;
  font-size: 12px;
}

.dealer-page-nav-panel-card .icon-options:last-child {
  padding-bottom: 10px;
  border-bottom: rgb(7, 204, 204) 2px solid;
}

.dealer-page-nav-panel-card .icon-options div span {
  padding-right: 10px;
  font-size: 20px;
}

.dealer-page-nav-panel-card .icon-options div {
  font-size: 12px;
}

.dealer-page-nav-panel-card .cards {
  padding-bottom: 20px;
  border-bottom: rgb(7, 204, 204) 2px solid;
}

.dealer-page-nav-panel-card .cards button.btn-lg {
  margin-top: 4rem;
}

.dealer-page-nav-panel-card .cards .row .col-md-4 {
  width: 100%;
  margin: 0 auto;
}

.dealer-page-nav-panel-cards .show-more {
  margin-top: 20px;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  background-color: #153e4d;
  border-color: #153e4d;
}

.dealer-reviews h4 {
  text-align: start;
  margin-top: 20px;
}

.dealer-reviews .dealer-ratings {
  width: 100%;
}

.dealer-reviews .dealer-ratings .average-rating {
  width: 35%;
  display: inline-block;
}

.dealer-reviews .dealer-ratings .detailed-rating {
  width: 65%;
  display: inline-block;
  text-align: start;
  margin: 0;
  padding: 0;
  padding-left: 4rem;
}

.dealer-reviews .dealer-ratings .detailed-rating div {
  display: inline-block;
  width: 50%;
}

.dealer-reviews .dealer-ratings .detailed-rating div h6 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
}

.dealer-reviews .dealer-ratings div .reviews-star .review-text {
  font-size: 12px;
  width: 100%;
}

.dealer-reviews .dealer-ratings div .stars {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(238, 182, 0);
  font-size: 14px;
}

.dealer-reviews .average-rating {
  background-color: #153e4d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 50px 80px;
}

.dealer-reviews .average-rating div:first-child {
  font-weight: bold;
}

.dealer-reviews .average-rating div span {
  font-size: 4rem;
  font-weight: bold;
}

.dealer-reviews .average-rating .stars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dealer-reviews .average-rating .stars {
  color: yellow;
  font-size: 24px;
}

.dealer-reviews .average-rating .ratings-amount {
  font-size: 11px;
  color: #ccc;
}

.dealer-review-card .detailed-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dealer-review-card .detailed-rating .overall {
  background-color: #153e4d;
  color: #fff;
  padding: 10px 30px;
  font-size: 12px;
}

.dealer-review-card .detailed-rating .overall h6 {
  font-size: 1.5rem;
  font-weight: bold;
}

.dealer-review-card .detailed-rating .stars {
  display: flex;
  justify-content: center;
  color: rgb(238, 182, 0);
}

.dealer-review-card .dealer-review-content {
  text-align: start;
  margin-top: 1.5rem;
  color: #153e4d;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.dealer-review-card .dealer-review-card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.dealer-review-card .dealer-review-card-description bold {
  font-weight: bolder;
}

.dealer-review-card .dealer-review-card-footer {
  font-size: 12px;
}

.dealer-page-nav-panel .dealer-write-review {
  margin-top: 2rem;
  text-align: start;
}

.dealer-page-nav-panel .dealer-write-review .form-group {
  margin-bottom: 2rem;
}

.dealer-page-nav-panel .dealer-write-review .form-group label {
  font-weight: bold;
}

.dealer-page-nav-panel .dealer-write-review .form-check label {
  color: #a8a8a8;
  margin-bottom: 2rem;
}

.dealer-page-nav-panel .dealer-write-review .inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dealer-page-nav-panel .dealer-write-review .inputs .input {
  display: flex;
}

/* .dealer-page-nav-panel .dealer-write-review .form-check {
  color: #a8a8a8;
} */
@media only screen and (max-width: 1000px) {
  .dealer-map {
    display: none;
  }

  .dealers {
    margin: 7rem 0 0px;
  }
}

@media only screen and (max-width: 900px) {
  .dealers-header h1 {
    width: 96%;
    margin: 0 auto;
    font-size: 20px;
  }

  .dealers-cards-wrapper,
  .dealers-sort {
    width: 90%;
    margin: 0 auto;
  }

  .dealers-card ul li {
    margin: 0 auto;
    width: 100%;
  }

  .dealers-card .dealers-number,
  .dealers-card .see-map,
  .dealers-card .dealer-logo {
    display: none;
  }

  .dealers-card .dealers-card-location-details {
    margin-right: 20px;
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {

  .dealers-cards-wrapper,
  .dealers-sort {
    width: 90%;
    margin: 0 auto;
  }

  .dealers-sort .card-body {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .dealers-sort-inputs {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .dealers-sort-inputs div {
    margin: 2px;
  }

  .dealers-card {
    margin: 0 auto;
  }

  .dealers-card ul li {
    margin: 0 auto;
    width: 100%;
  }

  .dealer-page-details {
    display: block;
    width: 100%;
  }

  .dealer-page-details-logo {
    width: 90%;
    margin: 0 auto;
    max-height: 300px;
  }

  .dealer-page-details-logo img {
    width: 100%;
  }

  .dealer-page-details-items {
    width: 100%;
    margin-top: 20px;
  }

  .dealer-page-nav-panel .dealer-panel-nav {
    display: block;
    font-size: 14px;
    font-weight: bold;
    text-align: start;
  }

  .dealer-page-nav-panel .dealer-panel-nav div {
    padding: 10px;
  }

  .dealer-panel-selected {
    width: 100%;
  }

  .dealer-reviews .dealer-ratings .average-rating {
    width: 100%;
    display: block;
  }

  .dealer-reviews .dealer-ratings .detailed-rating {
    width: 100%;
    display: block;
  }

  .dealer-reviews .average-rating .stars {
    text-align: center;
  }

  .dealer-review-card .detailed-rating {
    display: block;
  }

  .dealer-review-card .detailed-rating .overall {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 20px;
  }

  .dealer-review-card .detailed-rating div {
    text-align: start;
  }

  .dealer-review-card .detailed-rating div .reviews-star {
    display: flex;
    align-items: center;
  }

  .dealer-review-card .detailed-rating div .reviews-star div {
    margin-right: 10px;
    margin: 0;
    padding: 0;
  }

  .dealer-review-card .detailed-rating div.c-service,
  .dealer-review-card .detailed-rating div.buying-process,
  .dealer-review-card .detailed-rating div.overall-experience {
    margin-top: 20px;
  }

  .dealer-review-card-footer {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .dealers {
    margin-top: 7rem;
  }

  .dealers-card ul {
    display: block;
    text-align: start;
    margin: 15px 0px;
  }

  .dealer-reviews .dealer-ratings .detailed-rating div {
    width: 100%;
    display: block;
    padding-left: 0px;
  }

  .dealer-reviews .dealer-ratings .detailed-rating div .reviews-star {
    display: flex;
  }

  .dealer-reviews .dealer-ratings .detailed-rating div .stars {
    display: flex;
    justify-content: start;
  }

  .dealers-card {
    padding: 8px 0px;
  }

  .dealers-cards-title {
    padding-bottom: 20px;
    margin: 30px 0px 15px;
  }

  .dealers-review h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 420px) {
  .dealers {
    margin-top: 8rem;
  }
}

@media (max-width:1100px) {
  .dealers-header .wrapper {
    display: flex;
  }
}