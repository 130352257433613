/*footer*/
.col_white_amrc {
  color: #fff;
}

footer {
  width: 100%;
  background-color: #004f6e;
  min-height: 250px;
  padding: 10px 0px 25px 0px;
}

footer .wrapper {
  width: 80%;
  margin: 0 auto;
}

.first-header {}

.first-header img {
  max-width: 300px;
  text-align: left;
}

.pt2 {
  padding-top: 40px;
  margin-bottom: 20px;
}

footer p {
  margin-top: 3rem;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 0.25px;
  text-align: left;
  color: #ececec;
  width: 70%;
}

footer ul li {
  font-family: "Open Sans", " Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: 0.22px;
  text-align: left;
  color: #ececec;
}

.mb10 {
  padding-bottom: 15px;
}

.footer_ul_amrc {
  margin: 0px;

  list-style-type: none;
  font-size: 14px;
  padding: 0px 0px 10px 0px;
  text-align: left;
}

.footer_ul_amrc li {
  padding: 0px 0px 5px 0px;
}

.footer_ul_amrc li a {
  color: #ccc;
  text-align: start;
}

.footer_ul_amrc li a:hover {
  color: #fff;
  text-decoration: none;
}

.fleft {
  float: left;
}

.padding-right {
  padding-right: 10px;
}

.footer_ul2_amrc {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
  display: flex;
  justify-content: start;
}

.footer_ul2_amrc li {
  background-color: #153e4d;
  padding: 10px 20px;
  margin: 5px;
  color: #fff;
}

.footer_ul2_amrc li a {
  text-align: start;
  text-decoration: none;
  color: #fff;

  font-size: 20px;
}

.footer_ul2_amrc li a:hover {
  text-decoration: none;
}

.headin5_amrc {
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  text-transform: uppercase;
}

.bottom_border {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.foote_bottom_ul_amrc {
  list-style-type: none;
  padding: 0px;
  display: table;
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}

.foote_bottom_ul_amrc li {
  display: inline;
}

.foote_bottom_ul_amrc li a {
  color: #999;
  margin: 0 12px;
}

p.mb10 {
  font-size: 1em;
}

@media (max-width:1199px) {
  .first-header img {
    max-width: 230px;
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  footer ul li {
    font-size: 13px;
  }

  footer p {
    font-size: 14px;
    line-height: 1.2;
    width: 100%;
  }

  .pt2 {
    font-size: 17px;
  }

  .footer_ul2_amrc li {
    padding: 5px 10px;
  }

  .footer_ul2_amrc li a {
    font-size: 16px;
  }

  p.footer-bottom-p {
    font-size: 12px !important;
  }
}