.product-page {
  margin: 8rem 0 0;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
}

/* .product-main-wrapper */
.product-nav {
  background-image: url("../../../public/assets/newheader.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 20vh;
}

.product-wrapper {
  display: flex;
  width: 96%;
  margin: 0 auto;
}

div.product-main-content {
  width: 100%;
  margin-right: 2rem;
  text-align: start;
  margin-bottom: 5rem;
}

div.product-main-content .card {
  padding: 0px 20px;
  position: relative;
}

div.product-main-content h5 {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: left;
  color: #00425d;
  margin-top: 2rem;
  width: 90%;
}

div.product-main-content p.short-description {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

div.product-main-content .price-badge {
  text-align: end;
  position: absolute;
  right: 0;
  top: 2rem;
}

// BADGE START

div.product-main-content .price-badge a {
  display: inline-block;
  padding: 10px 10px 10px 20px;
  text-decoration: none;
  color: #fff;
  position: relative;
  text-align: end;
  width: 100%;

  >span {
    position: relative;
    text-align: end;
    z-index: 5;
    font-family: "Montserrat", "Open Sans", sans-serif;

    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.56px;
    color: #fff;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #dc562c;
    border-width: 3px 3px 3px 0;
    background-color: #dc562c;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    bottom: 0;
    width: 30px;
    border: solid 1px #dc562c;
    border-width: 3px 0 3px 3px;
    background-color: #dc562c;
    z-index: 8;
    transform: skewX(-25deg);
  }
}

// BADGE END

div.product-main-content .price-badge button {
  padding: 9.2px 14.6px 9.2px 62.6px;
  box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);
  background-color: #df4e24;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.product-card-body-carousel {
  display: flex;
  margin-top: 2rem;
}

.product-card-body-carousel .left {
  width: 60%;
  padding: 0px;
}

.product-card-body-carousel .right {
  width: 40%;
  padding: 20px 20px;
}

.product-card-body-carousel .left .carousel img {
  /* width: 900px;
  height: 400px; */

  margin: auto;
}

.product-card-icon-options div {
  width: 50%;
  display: inline-block;
  text-align: start;
  margin: 0 auto;
  padding: 1px;
  color: #133440;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.48px;
  text-align: left;
  color: #000;
}

.product-card-icon-options div span {
  padding-right: 10px;
  font-size: 24px;
  color: #002e5b;
}

.product-card-list-details {
  margin: 0 auto;
  display: flex;
}

.product-card-list-details ul {
  width: 60%;
  display: inline-block;
  text-align: start;
  margin: 0 auto;
  padding: 1px;
  margin-top: 20px;
}

.product-card-list-details ul li {
  list-style: none;
  text-align: start;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dbdbdb;
  padding: 5px;
}

.product-card-description {
  border-top: 1px solid #004f6e;
  padding-top: 20px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.8px;
  text-align: left;
  color: #000;
}

.product-card-description h6 {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.66px;
  text-align: left;
  color: #002e5b;
}

.product-card-description p {
  font-size: 12px;
}

.product-card-list-details ul li div {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.54px;
  text-align: left;
}

.product-card-list-details ul li div:last-child {
  width: 70%;

  color: #747678;
}

.product-card-list-details ul li div:first-child {
  color: #002e5b;

  width: 50%;
  text-align: start;
}

.product-card-contact-details ul {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.product-card-contact-details ul li {
  list-style: none;
  padding: 4.9px 0px 6.2px 0.1px;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.36px;
  text-align: left;
  color: #00425d;
}

.product-card-contact-details ul li:last-child {
  margin-left: 3rem;
}

.product-card-contact-details ul li svg:last-child {
  margin-left: 10px;
}

.product-card-contact-details ul li div {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #00859d;
}

.product-card-contact-details .share-this {
  padding: 5px 22px 5px 29px;
  border-radius: 23px;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #5f6161;
  border: solid 1px #d4d1d1;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  margin-left: 3rem;
  margin-bottom: 10px;
}

.product-card-contact-details .share-this:hover {
  color: #000;
}

.product-card-contact-details ul li.dots {
  border-bottom: 1px dotted #333;
}

.product-card-contact-details ul li button {
  font-size: 11px;
}

.product-card-contact-details ul li button svg {
  padding-right: 5px;
}

.product-card-wav-details-wrapper {
  padding: 40px 10px;
  border-bottom: #004f6e solid 1px;
  border-top: #004f6e solid 1px;
  width: 96%;
  margin: 0 auto;
}

.product-card-wav-details {
  display: inline-block;
  width: 100%;
}

.product-card-wav-detail {
  width: 33%;
  float: left;
}

.product-card-wav-detail ul {}

.product-card-wav-detail ul li {
  list-style: none;
  border-bottom: 1px solid #d4d4d4;
  padding: 10px 0;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  text-align: start;
  margin: 5px 30px;
}

.product-card-wav-detail ul li:last-child {
  border: none;
}

.product-card-wav-detail ul:last-child li:last-child {
  border-bottom: 1px solid #d4d4d4;
}

.product-card-wav-detail ul li div.bold {
  // display: flex;
  // align-items: center;
  // justify-content: start;
  width: 100%;
  text-align: start;
}

.product-card-wav-detail ul li div.bold div {
  margin-right: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.54px;
  text-align: start;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 16px;
}

.product-card-wav-detail ul li div.bold div.text {
  font-weight: bold;
  width: 40%;
  display: inline-block;
  color: #002e5b;
}

.product-card-wav-detail ul li div.bold div.normal-text {
  color: #747678;
  width: 50%;
  display: inline-block;
  text-align: left;
  font-weight: 600;
}

.product-card-wav-detail ul li div.bold .icon img {
  width: 30px;
}

.product-card-wav-features-wrapper {
  padding: 40px 10px;
  border-bottom: #004f6e solid 1px;
  width: 96%;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.product-card-wav-features {
  display: inline-block;
  width: 100%;
  color: #707070;
  font-weight: 600;
}

/* .product-card-wav-features .product-card-wav-feature {
  float: left;
  width: 25%;
} */
.product-card-wav-features .product-card-wav-feature {
  list-style: none;
  margin: 5px 30px;
  padding: 10px 0;
  display: inline-block;
  width: 30%;
}

.product-card-wav-features .product-card-wav-feature span svg {
  color: #df4e24;
  font-size: 20px;
  margin-right: 10px;
}

.product-card-contact-details ul li img.product-owner-img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

/* SIDEBAR */

.product-sidebar {
  width: 30%;
  margin-top: 2.3rem;
}

.product-sidebar-header {
  background-color: #00425d;
  padding: 20px 20px 15px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.product-sidebar-header h1 {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  color: #fff;
}

.product-sidebar-header h1 span {
  margin-right: 10px;
}

.product-sidebar-main {
  margin-top: 5px;
  border-top: #df4e24 2px solid;
  background-color: #fff;
  padding: 20px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.product-sidebar-main-company h5 {
  font-weight: bold;
  margin-top: 10px;
}

.product-sidebar-main-company img {
  width: 200px;
  max-height: 100px;
}

.product-sidebar-main-contact-detail {
  display: flex;
  align-items: center;
}

.product-sidebar-main-contact-detail div {
  margin-top: 15px;
}

.product-sidebar-main-contact-detail .icon svg {
  font-size: 20px;
  color: #00425d;
  margin-right: 15px;
}

.product-sidebar-main-contact-detail .normal-text {
  font-size: 15px;
  color: #333;
  text-align: left;
}

.product-sidebar-main-contact-detail:first-child div.normal-text {
  text-decoration: dotted;
}

.product-sidebar-main-contact-details-inputs h3 {
  text-align: center;
  color: #000;
  font-weight: 16px;
  font-weight: bold;
  margin-top: 30px;
}

.product-sidebar-main-contact-details-inputs small {
  color: #333;
}

.product-sidebar-main-contact-details-inputs input {
  font-size: 13px;
}

.product-sidebar-main-contact-details-inputs .btn {
  background-color: #dc562c;
  padding: 9px 80px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #fff;
}

@media only screen and (max-width: 1500px) {
  .product-card-contact-details ul li {
    font-size: 15px;
    line-height: 1.5;
  }

  .product-card-contact-details ul li div {
    font-size: 16px;
  }

  .product-card-contact-details .share-this {
    padding: 5px 15px 5px 22px;
    line-height: 1.2;
  }

  .product-card-wav-detail ul li div.bold div {
    font-size: 15px;
  }

  .product-card-list-details ul {
    width: 80%;
  }

  .product-sidebar {
    width: 30%;
  }

  .product-sidebar-header h1 {
    font-size: 17px;
  }

  .product-sidebar-main {
    padding: 20px;
    font-size: 14px;
  }

  .product-sidebar-main-company h5 {
    font-size: 14px;
  }

  .product-sidebar-main-company img {
    max-width: 200px;
    max-height: 100px;
  }

  .product-sidebar-main-contact-detail .icon svg {
    font-size: 20px;
  }

  .product-sidebar-main-contact-detail .normal-text {
    font-size: 13px;
  }

  .product-sidebar-main-contact-details-inputs h3 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1250px) {
  .product-card-contact-details ul li {
    font-size: 13px;
    line-height: 1.2;
  }

  .product-card-contact-details ul li:last-child {
    margin-left: 1rem;
  }

  .product-card-contact-details ul li div {
    font-size: 13px;
  }

  .product-card-contact-details .share-this {
    padding: 5px 15px 5px 22px;
    line-height: 1.1;
  }

  .product-card-contact-details ul li:last-child {
    margin-left: 1rem;
  }

  div.product-main-content .price-badge {
    text-align: end;
    position: absolute;
    right: 0;
    top: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .product-sidebar-header h1 {
    font-size: 20px;
  }

  .product-card-wav-detail {
    width: 50%;
    float: left;
  }

  .product-card-wav-features .product-card-wav-feature {
    float: left;
    width: 50%;
  }

  .product-card-wav-detail ul li div.bold div {
    font-size: 14px;
  }

  div.product-main-content h5 {
    margin-top: 5rem;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .product-page {
    margin: 6rem 0 0;
  }

  .product-wrapper {
    display: inline-block;
  }

  div.product-sidebar {
    width: 96%;
    color: #fff;
    position: relative;
    margin: 2rem auto;
  }

  div.product-sidebar ul {
    margin-left: 10%;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  div.product-sidebar ul li {
    width: 40%;
  }

  div.product-main-content {
    width: 96%;
    margin: 0 auto;
    text-align: start;
  }
}

@media only screen and (max-width: 768px) {
  .product-card-body-carousel {
    display: block;
  }

  .product-card-body-carousel .left {
    width: 100%;
    padding: 0px;
  }

  .product-card-body-carousel .right {
    width: 100%;
    padding: 20px 20px;
    margin: 0 auto;
  }

  .product-card-contact-details ul {
    width: 100%;
    margin-top: 20px;
    display: block;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .product-card-contact-details ul li:last-child {
    margin-left: 1rem;
  }

  .product-card-contact-details ul li {
    margin-top: 5px;
  }

  .product-nav {
    background-image: url("../../../public/assets/newheader.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 20vh;
  }

  .product-nav h1 {
    font-size: 20px;
    padding: 5px;
  }

  .product-sidebar-header h1 {
    font-size: 20px;
  }

  .product-sidebar-header p {
    font-size: 13px;
  }

  .product-card-wav-detail {
    width: 100%;
    float: left;
  }

  .product-card-wav-detail ul li div.bold div {
    font-size: 13px;
  }

  .product-card-list-details ul {
    width: 100%;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 530px) {
  .product-card-contact-details ul li img.product-owner-img {
    height: 54px;
    width: 54px;
  }

  div.product-main-content h5 {
    font-size: 20px;
  }

  .wrapper.showcase-wrapper .cards .mt-5 {
    margin-top: 1.5rem !important;
  }

  .product-card-wav-features-wrapper {
    padding: 40px 0;
  }

  .product-sidebar-header h1 {
    font-size: 18px;
  }

  .product-card-wav-details-wrapper {
    width: 100%;
  }

  div.product-sidebar {
    width: 98%;
    color: #fff;
    position: relative;
    margin: 0 auto;
  }

  div.product-sidebar .product-sidebar-header {
    margin: 20px 0px 10px;
    padding: 30px 5px 20px;
  }

  div.product-sidebar ul {
    margin: 0;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  div.product-sidebar ul li {
    width: 96%;
  }
}

@media only screen and (max-width: 420px) {
  .product-card-contact-details ul li {
    font-size: 12px;
    line-height: 1.1;
  }

  .product-card-contact-details ul li div {
    font-size: 13px;
  }

  .product-card-contact-details .share-this {
    margin: 1rem 0;
  }

  .product-card-wav-detail ul li div.bold div.text {
    width: 35%;
  }

  .product-card-wav-detail ul li div.bold div.normal-text {
    width: 45%;
  }
}


@media (max-width:375px) {
  .product-card-contact-details ul {
    display: flex;
    flex-direction: column;
  }

  .product-card-contact-details ul li.number {
    margin-left: 0;
  }

  .form-check-label {
    text-align: left;
  }

  .product-sidebar-main-contact-details-inputs small {
    margin-left: 0 !important;
  }

  .product-sidebar-main-contact-details-inputs small br {
    display: none;
  }
}