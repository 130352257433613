#sell-your-wav {
  min-height: 100vh;
  margin: 8rem 0 0;
  padding: 0;
  background-color: #f2f3f3;
  font-family: Montserrat;
}

#sell-your-wav .sell-your-wav-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 30vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}

#sell-your-wav .sell-your-wav-header .wrapper {
  min-height: 30vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}

#sell-your-wav .sell-your-wav-header .wrapper .text-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}

#sell-your-wav .sell-your-wav-header .wrapper .text-wrapper .subtitle {
  font-size: 15px;
  color: rgb(223, 223, 223);
}

/* ADVERTISEMENT */
#sell-your-wav .sell-your-wav-ad {
  width: 70%;
  margin: 0 auto;
  padding: 5rem 0px;
}

#sell-your-wav .sell-your-wav-ad .top {
  width: 40%;
  margin: 0px auto 2rem;
}

#sell-your-wav .sell-your-wav-ad .top h3 {
  font-size: 20px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-ad .top p {
  font-size: 13px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-ad .top p:last-child {
  width: 80%;
  margin: 0 auto;
}

#sell-your-wav .sell-your-wav-ad .bottom {
  border-top: 1px #153e4d solid;
}

#sell-your-wav .sell-your-wav-ad .bottom h3 {
  font-size: 20px;
  color: #153e4d;
  font-weight: bold;
  margin: 2rem 0px;
}

#sell-your-wav .sell-your-wav-ad .bottom .steps {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-ad .bottom .steps .step div:first-child {
  background-color: rgb(233, 68, 3);
  width: 60px;
  padding: 5px;
  margin: 0 auto 10px;
  border-radius: 3px;
  color: #fff;
}

/* PRICING */
#sell-your-wav .sell-your-wav-pricing {
  min-height: 60vh;
  background-image: url("https://news.motability.co.uk/wp-content/uploads/2022/02/wheelchair-accessible-vehicle-expert-advice-motability-scheme.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top: 3px solid rgb(233, 68, 3);
  width: 100%;
}

#sell-your-wav .sell-your-wav-pricing .wrapper {
  display: block;
  background-color: rgba(0, 75, 145, 0.8);
  padding: 3rem;
  width: 100%;
}

#sell-your-wav .sell-your-wav-pricing .wrapper h1 {
  color: #fff;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing {
  height: 410px;
  width: 500px;
  border-top: 4px rgb(233, 68, 3) solid;
  border-bottom: 4px rgb(233, 68, 3) solid;
  background-color: #f2f3f3;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing:first-child {
  border-left: 4px rgb(233, 68, 3) solid;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing:last-child {
  border-right: 4px rgb(233, 68, 3) solid;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .center-pricing {
  height: 550px;
  width: 400px;
  border: 4px rgb(233, 68, 3) solid;
  border-radius: 20px;
  background-color: #f2f3f3;
}

#sell-your-wav .sell-your-wav-pricing h1 {
  margin: 3rem 0;
  font-size: 30px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing,
.center-pricing {
  padding: 20px 10px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing h3,
.center-pricing h3 {
  font-size: 23px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing h6,
.center-pricing h6 {
  font-size: 14px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing .day-and-price,
.center-pricing .day-and-price {
  font-size: 14px;
  color: rgb(143, 138, 138);
  margin-bottom: 20px;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing .lets-talk {
  font-size: 18px;
  color: rgb(143, 138, 138);
  margin-bottom: 20px;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing .day-and-price span,
.center-pricing .day-and-price span {
  font-size: 16px;
  color: #153e4d;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards div .listings .listing {
  display: flex;
  /* justify-content: space-between; */
  text-align: start;
  font-size: 13px;
  color: #153e4d;
  margin: 20px 0px;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards div .listings .listing .icon {
  margin-right: 10px;
}

#sell-your-wav .sell-your-wav-pricing .pricing-cards button {
  background-color: rgb(233, 68, 3);
  border-color: rgb(233, 68, 3);
  color: #fff;
  padding: 5px;
  font-size: 14px;
  border-radius: 3px;
}

.btn-margin {
  margin-top: 180px;
}

/* MEDIA */
@media only screen and (max-width: 1300px) {
  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing {
    height: 400px;
    width: 550px;
    padding-bottom: 0;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .center-pricing {
    height: 400px;
    width: 500px;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing,
  .center-pricing {
    padding: 20px 10px;
    color: #153e4d;
  }
}

@media only screen and (max-width: 1150px) {
  #sell-your-wav .sell-your-wav-ad {
    padding: 0rem 0px 3rem;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing {
    width: 550px;
  }

  .pricing-cards .center-pricing {
    height: 400px;
    width: 550px;
  }
}

@media only screen and (max-width: 1024px) {
  #sell-your-wav {
    margin: 6rem 0 0px;
  }

  #sell-your-wav .sell-your-wav-header .wrapper .text-wrapper {
    align-items: center;
    margin-top: 5rem;
  }

  #sell-your-wav .sell-your-wav-header .wrapper .text-wrapper h1 {
    font-size: 25px;
  }

  #sell-your-wav .sell-your-wav-header .wrapper .text-wrapper .subtitle {
    font-size: 15px;
    margin-top: 1rem;
  }

  #sell-your-wav .sell-your-wav-ad {
    width: 90%;
    margin: 0 auto;
  }

  #sell-your-wav .sell-your-wav-ad .top {
    width: 80%;
    margin: 3rem auto 2rem;
  }

  #sell-your-wav .sell-your-wav-ad .bottom .steps {
    justify-content: space-between;
  }

  #sell-your-wav .sell-your-wav-ad .bottom .steps .step div:first-child {
    width: 50px;
    padding: 5px;
    font-size: 13px;
    margin-bottom: 10px;
  }

  #sell-your-wav .sell-your-wav-ad .bottom .steps .step div:last-child {
    font-size: 13px;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing,
  .center-pricing {
    padding: 10px;
    color: #153e4d;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards div .listings .listing {
    display: flex;
    text-align: start;
    font-size: 13px;
    color: #153e4d;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 950px) {

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing h3,
  .center-pricing h3 {
    font-size: 18px;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing h6,
  .center-pricing h6 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  #sell-your-wav {
    margin: 5rem 0;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards {
    display: block;
    margin-bottom: 3rem;
    width: 100%;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .side-pricing {
    height: auto;
    width: 100%;
    border: 4px rgb(233, 68, 3) solid;
    border-radius: 20px;
    margin: 20px auto;
  }

  #sell-your-wav .sell-your-wav-pricing .pricing-cards .center-pricing {
    height: auto;
    width: 100%;
  }

  #sell-your-wav .sell-your-wav-header .wrapper {
    margin-top: 2rem;
  }
}

.mob_arrow1 {
  display: none;

}

.hidedropdown .mob_arrow1 {
  transform: rotate(-90deg);
  transition: .3s ease;
}

nav.main-navbar ul li.hidedropdown div#dropdown {
  display: block;
}

.overflow_hidden {
  overflow: hidden;
}

@media (max-width:1024px) {
  .main-navbar {
    padding-bottom: 40px !important;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    padding: 10.2px 8px 10.9px 8px;
  }

  .mob_close {

    text-align: right;
    padding-right: 14px;
    margin-top: -19px;
    margin-bottom: 12px;
  }

  .mob_arrow_span {
    position: absolute;
    left: 30px;
    top: 50%;
    width: 100%;
    height: 100%;
    display: block;
    transform: translate(0, -50%);
    min-width: 153px;
    text-align: right;
  }


  .mob_arrow1 {

    /* transform: translate(0, -50%) rotate(90deg); */
    transform: rotate(90deg);
    font-size: 20px;
    transition: .3s ease;
    display: block;
    position: absolute;
    right: 0;
    line-height: .6;
  }

  nav.main-navbar ul li a {
    position: relative;
    display: inline-block;
  }
}

@media (max-width:575px) {
  .top-nav .wrapper .compare-nav .login svg {
    margin: 5px 0px 5px 6px;
  }

  .mob_view img {
    max-width: 76%;
  }

  nav.main-navbar ul li {
    margin: 0px 13px 16px 15px;
  }

  nav.main-navbar ul li:first-child {
    padding-top: 0;
  }

  .top-nav .login {
    font-size: 13px;
    display: inline-flex;
    align-items: center;
  }

  .wrapper.d-flex.align-items-center {
    flex-wrap: unset !important;
    width: 100%;
  }

  .btn-margin {
    margin-top: 0;
  }

  #sell-your-wav .sell-your-wav-pricing .wrapper {
    padding: 1.2rem;
  }

  #sell-your-wav .sell-your-wav-pricing h1 {
    margin: 1rem 0;
    font-size: 23px;
  }
}


@media only screen and (max-width: 550px) {
  #sell-your-wav .sell-your-wav-ad {
    width: 98%;
    margin: 0 auto;
  }

  #sell-your-wav .sell-your-wav-ad .bottom .steps {
    display: block;
    width: 98%;
    margin: 0 auto;
  }

  #sell-your-wav .sell-your-wav-ad .bottom .steps .step div:first-child {
    margin-top: 10px;
  }
}




/* Wav tile css */


img {
  max-width: 100%;
}

.active_list {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 38px;
  padding: 68px 38px 30px 95px;
  overflow: hidden;
  margin-bottom: 45px;
}

.active_list span.list_label1.ended_label1 {
  background: #A7A7A7 0% 0% no-repeat padding-box;
}

.active_list span.list_label1 {
  background: #28B8CB;
  box-shadow: 2px 2px 4px #0707077C;
  letter-spacing: 0.87px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 3px 49px;
  transform: rotate(322deg);
  display: inline-block;
  position: absolute;
  left: -64px;
  min-width: 227px;
  text-align: center;
  top: 24px;
  text-transform: uppercase;
}

.active_list h2 {
  font-weight: 600;
  letter-spacing: 0.87px;
  color: #00425D;
  font-size: 25px;
}

.active_list p {
  font-size: 13px;
  color: #000000;
  font-weight: 500;
}

.active_list li {
  list-style: none;
  display: flex;
  border-bottom: 2px solid #DBDBDB;
  margin: 11px 0;
  padding-bottom: 5px;
}

.active_list h4 {
  letter-spacing: 0.39px;
  font-size: 13px;
  color: #002E5B;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 95px;
}

.active_list h5 {
  letter-spacing: 0.39px;
  font-size: 13px;
  color: #747678;
  text-transform: uppercase;
  font-weight: 600;
}

.last_border li:last-child {
  border-bottom: 0;
}

.last_border li:last-child h5 {
  text-transform: unset;
}

.car_list_img {
  box-shadow: 0px 0px 15px #00000061;
  border: 1px solid #707070;
  border-radius: 25px;
  overflow: hidden;
  height: 219px;
  width: 100%;
}

.car_list_img img.main_car_img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photos_num1 {
  background: #6767638f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  display: inline-flex;
  padding: 8px 15px;
  align-items: center;
  position: absolute;
  right: 0;
  top: 32px;
  cursor: pointer;
}

.photos_num1 img {
  width: 19px;
  filter: brightness(0) invert(1);
  margin-right: 11px;
}

.btn.manage_extend_btn {
  font-size: 10px;
  background: #28B8CB 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #0707077C;
  color: #fff;
  padding: 3px 6px;
  margin: 0 4px 2px 0;
}

.btn.end_btn {
  font-size: 10px;
  background: #FF2B2B 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #0707077C;
  color: #fff;
  padding: 3px 4px;
  margin: 0 4px 2px 0;
}

.list_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width:1199px) {
  .active_list h2 {
      font-size: 20px;
  }

  .active_list span.list_label1 {
      font-size: 17px;
  }

  .active_list h4 {
      min-width: 96px;
      font-size: 12px;
  }

  .active_list h5 {
      font-size: 12px;
  }

  .active_list {
      padding: 53px 30px 30px 88px;
  }
}

@media (max-width:991px) {
  .list_row_reverse {
      flex-direction: column-reverse;
  }

  .list_btn {
      margin-bottom: 15px;
  }
}

@media (max-width:575px) {

  .active_list span.list_label1 {
      left: -74px;
      font-size: 15px;
      top: 20px;
  }

  .active_list h2 {
      font-size: 18px;
  }

  .active_list {
      padding: 53px 30px 30px 45px;
  }

  .car_list_img {
      height: 190px;
  }

}
/* Wav tile css ended */