.about {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  padding: 5rem 10px;
}
.about .text {
  padding: 40px 0px 20px;
}
.about .image {
  padding: 70px 0px 20px;
}
.about div.text h1 {
  color: #004f6e;
  font-size: 30px;
  margin: 0px 0px 40px;
  font-weight: bold;
}
.about div.text p {
  font-size: 17px;
  margin: 0px 0px 30px;
  color: #133440;
}

.about ul li {
  list-style: none;
  text-align: start;
}
.about ul li span {
  padding-right: 10px;
  color: #133440;
}
.about ul li span svg {
  font-size: 23px;
  color: #133440;
}
.about ul li a {
  text-decoration: none;
  color: inherit;
}
img {
  max-width: 500px;
  width: fit-content;
  /* max-height: 500px; */
}
@media only screen and (max-width: 768px) {
  .about {
    padding-top: 7rem;
    display: block;
    /* margin: 10px; */
  }
  .about div.text h1 {
    font-size: 25px;
  }
  .about div.text p {
    font-size: 14px;
    width: 98%;
    margin: 0 auto 30px;
  }
  img {
    max-width: 400px;
    width: fit-content;
  }
  .about ul li {
    font-size: 14px;
  }
  .about ul li span svg {
    font-size: 18px;
  }
}
@media only screen and (max-width: 500px) {
  img {
    max-width: 300px;
    width: fit-content;
    /* max-height: 500px; */
  }
}
