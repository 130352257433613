#signUpSignIn {
  margin: 0;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
}
#signUpSignIn .header-wrapper {
  background-image: url("https://www.financialexpress.com/wp-content/uploads/2021/12/1-445.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 20vh;
}
#signUpSignIn .header {
  padding-left: 10%;
  color: #fff;
  font-size: 2.2rem;
  font-weight: bold;
  height: 20vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#signUpSignIn .main-content {
  width: 80%;
  margin: 4rem auto;
}
#signUpSignIn h1 {
  margin-bottom: 20px;
  text-align: start;
  font-weight: bold;
}

#signUpSignIn .main-content .sign-in form {
  background-color: #133440;
  padding: 2rem 1rem;
  border-radius: 5px;
  margin-right: 3rem;
  color: #fff;
}
#signUpSignIn .main-content .sign-in form div.form-group {
  margin-bottom: 2rem;
}

#signUpSignIn .main-content .sign-in form input {
  padding: 10px 5px;
}
#signUpSignIn .main-content .sign-in form label {
  font-size: 1.4rem;
  font-weight: bold;
}
#signUpSignIn .main-content .sign-in form label:last-child {
  font-size: 12px;
  color: rgb(160, 157, 157);
  display: flex;
  justify-content: space-between;
}
#signUpSignIn .main-content .sign-in button {
  background-color: #dd572c;
  border-color: #dd572c;
  padding: 5px 30px;
  margin-top: 20px;
}
#signUpSignIn .main-content .sign-in .connect-with-social-media {
  background-color: #fff;
  border-top: 2px solid #133440;
  padding: 1.5rem;
  margin-top: 20px;
  width: 90%;
}
#signUpSignIn .main-content .sign-in .connect-with-social-media .icons {
  display: flex;
}
#signUpSignIn .main-content .sign-in .connect-with-social-media .icons .icon {
  font-size: 30px;
  margin-right: 10px;
}
#signUpSignIn .main-content .sign-up .row {
  background-color: #fff;
  padding: 2rem 1rem;
  border-top: 3px solid #004f6e;
  border-radius: 5px;
}

#signUpSignIn .main-content .sign-up .row input {
  background-color: #f2f3f3;
}
#signUpSignIn .main-content .sign-up .row input:focus {
  background-color: #fff;
}
#signUpSignIn .main-content .sign-up .row label {
  font-size: 1.4rem;
  font-weight: bold;
}
#signUpSignIn .main-content .sign-up .row .lower {
  padding: 0px 1rem;
}
#signUpSignIn .main-content .sign-up .row div.form-check label {
  font-size: 12px;
  color: rgb(160, 157, 157);
}
#signUpSignIn .main-content .sign-up .row button {
  background-color: #dd572c;
  border-color: #dd572c;
  padding: 5px 10px;
  margin-top: 10px;
}
@media only screen and (max-width: 768px) {
  #signUpSignIn .main-content .sign-up {
    margin-top: 30px;
  }
  #signUpSignIn .main-content .sign-in form label {
    font-size: 1rem;
    font-weight: bold;
  }
  #signUpSignIn .main-content .sign-up .row label {
    font-size: 1rem;
    font-weight: bold;
  }
}
