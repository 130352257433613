.social_btn .kep-login-facebook {
    font-size: 13px;
    text-transform: capitalize;
    height: 42px;
    border-radius: 0px !important;
    padding: 0 10px 0px 0px;
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 25%);
}
.social_btn .kep-login-facebook i{
    margin-top: 1px;
    width: 39px;
    height: 36px;
    background-color: #fff;
    color: #4c69ba;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    border-radius: 2px;
}
.social_btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  cursor: pointer;
  margin: 10px;
}

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  .btn-text:hover {
    box-shadow: 0 0 6px #4285f4;
  }
  .btn-text:active {
    background: #1669F2;
  }


@import url(https://fonts.googleapis.com/css?family=Roboto:500);