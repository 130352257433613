#profile {
  min-height: 100vh;
  margin: 8rem 0;
  padding: 0;
  background-color: #f2f3f3;
}
#profile table tbody td {
  text-transform: capitalize;
}
#profile .profile-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 25vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}
#profile .profile-header .wrapper {
  min-height: 25vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}
#profile .profile-header .wrapper .text-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
#profile .profile-header .wrapper .text-wrapper .subtitle {
  font-size: 12px;
  color: rgb(223, 223, 223);
}
#profile .profile-main {
  width: 80%;
  margin: 2rem auto;
}
#profile .profile-main .dashboard-side {
  color: #153e4d;
}
#profile .profile-main .dashboard-side h3 {
  color: #153e4d;
  text-align: end;
  /* margin-bottom: 3rem; */
  font-size: 20px;
}
#profile .profile-main .dashboard-side h3.dashboard {
  color: #153e4d;
  text-align: end;
  margin-bottom: 3rem;
  font-size: 24px;
}
#profile .profile-main .dashboard-side div div {
  padding: 10px;
  text-align: end;
  cursor: pointer;

  margin-left: auto;
}
#profile .profile-main .dashboard-side div div:hover {
  background-color: #153e4d;
  color: #fff;
  padding: 10px;
}
.dashboard-side-selected {
  background-color: #153e4d !important;
  color: #fff !important;
  padding: 10px !important;
}

#profile .profile-main .dashboard-side .dashboard-side-items div {
  color: #153e4d;
  font-weight: 500;
}
#profile .profile-main .dashboard-main .your-listings {
  background-color: #fff;
  padding: 0;
  margin-left: 3rem;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

#profile .profile-main .your-listings .header {
  background-color: rgb(233, 68, 3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 0px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

#profile .profile-main .your-listings .header h3 {
  font-size: 20px;
  margin-top: 5px;
}
#profile .profile-main .your-listings .header svg {
  margin-right: 2rem;
  font-size: 25px;
}
#profile .profile-main .your-listings .wav-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}
#profile .profile-main .your-listings .wav-actions div.wav-length,
#profile .profile-main .your-listings .wav-actions div.credits {
  margin: 0 10px 0 0;
  width: 220px;
  height: 120px;
  padding: 5px 20px 0px;
  border-radius: 42px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
  border: solid 3px #dc562c;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
#profile
  .profile-main
  .your-listings
  .wav-actions
  div.wav-length
  .action-header,
#profile .profile-main .your-listings .wav-actions div.credits .action-header {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #002e5b;
  text-transform: uppercase;
}
#profile
  .profile-main
  .your-listings
  .wav-actions
  div.wav-length
  .action-detail,
#profile .profile-main .your-listings .wav-actions div.credits .action-detail {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: center;
  color: #002e5b;
}
#profile .profile-main .your-listings .wav-actions .wav-create {
  background-color: #dc562c;
  border-radius: 42px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
  border: solid 3px #dc562c;
  width: 220px;
  height: 120px;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
#profile .profile-main .your-listings .wav-actions .wav-create img {
  height: 70px;
}
.choose_file p{
  cursor: pointer;
}
.choose_file {
  border-radius: 0;
  background-color: #91cc00;
  padding: 12px 43px;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.18px;
  color: #fff;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 8px 0 10px !important;
}
.choose_file input{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
#profile .profile-main .your-listings .choose-photos {
  margin: 2rem 0;
  padding: 2rem;
}
#profile .profile-main .your-listings .choose-photos h3 {
  font-size: 16px;
  color: #153e4d;
  text-align: start;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #0b1611;
  padding-bottom: 5px;
}
#profile .profile-main .your-listings .upload-photos {
  padding: 1rem;
}
#profile .profile-main .your-listings .upload-photos label {
  margin: 1rem 0px;
  float: left;
}
#profile .profile-main .your-listings .upload-photos label .icon {
  background-color: #ddd;
  color: rgb(155, 154, 154);
  padding: 10px 40px;
}
#profile .profile-main .your-listings .upload-photos label .icon svg {
  color: rgb(155, 154, 154);
  font-size: 100px;
}
#profile .profile-main .your-listings .upload-photos label .text {
  background-color: rgb(9, 165, 9);
  color: #fff;
  padding: 10px;
  width: 80%;
  margin: 2rem auto;
}
#profile .profile-main .your-listings .upload-photos label .text svg {
  margin-right: 10px;
  font-size: 1.5rem;
  margin-top: -5px;
}
#profile .profile-main .your-listings .vehicle-details {
  text-align: start;
}
.img_row_box p{
  margin-bottom: 0;
}
.img_row1 img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 11px;
}
.img_row_box span svg path{
  fill: white;
  stroke: white;
}
.img_row_box span{
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #dc562c;
  position: absolute;
  right: -9px;
  top: -11px;
}
.img_row_box{
  border: 1px solid lightgray;
  border-radius: 11px;
  position: relative;
  margin-top: 15px;
}
#profile
  .profile-main
  .your-listings
  .vehicle-details
  .form-group
  select.form-control, .form-control {
  padding: 10px;
  height: auto;
}
#profile .profile-main .your-listings .select-features {
  margin-top: 2rem;
  width: 100%;
}
#profile .profile-main .your-listings .select-features h3 {
  text-align: center;
  padding: 10px 0px 20px;
}
#profile .profile-main .your-listings .select-features .features {
  display: flex;
  flex-wrap: wrap;
}
/* #profile .profile-main .your-listings .select-features .features button {
  width: auto;
  padding: 5px 2rem;
  border-color: #117986;
  background-color: #fff;
  color: #117986;
  text-transform: capitalize;
  border-radius: 40px;
  margin: 10px 5px;
  font-size: 14px;
} */
.feature-selected {
  background: #28b8cb!important;
  color: #fff!important;
  border: 1px solid #fff!important;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 6%), 0 6px 20px 0 rgb(0 0 0 / 7%);
}
.select-features.accr .accordion.active {
  background: #fff;
}
.select-features.accr .accordion.active  span svg path {
  fill: #153e4d;
}
.select-features .accr .accordion {
  border-bottom: 2px solid #dcdcdc!important;
}
.select-features.accr .accordion.bottom-b {
    border-bottom: 3px solid rgb(0 0 0 / 6%);
    border-left: 0;
    border-top: 0;
    border-right: 0;
}
p.descr {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}
.select-features.accr .features.accordion {
  padding-top: 2px;
}
h3.main-heading p.f-vehicle {
  font-size: 17px;
  font-weight: 300;
  margin: 0 0px 0 14px;
}
.go-back.text-left {
  margin-bottom: 4rem;
}
h3.main-heading {
  background: #002e5b;
  text-align: left!important;
  padding: 27px!important;
  color: #fff!important;
  display: flex;
  margin: 0!important;
  font-size: 30px!important;
  text-transform: uppercase;
  align-items: center;
}
.select-features.accr  .accordion.active .accordion__title {
  font-size: 21px;
  color: #153e4d;
}
.select-features.accr .accordion__section {
  border: none!important;
}
.accordion__section {
  display: flex;
  flex-direction: column;
  border: 1px dotted rgba(0, 0, 0, .125);
  width: 100%;
}
#profile .profile-main .your-listings .select-features .features button {
  width: auto;
  padding: 5px 2rem;
  border-color: #117986;
  background-color: #fff;
  color: #117986;
  text-transform: capitalize;
  border-radius: 40px;
  margin: 10px 5px;
  font-size: 14px;
  border: 1px solid #417f95;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 6%), 0 6px 20px 0 rgb(0 0 0 / 7%);
}
/* .feature-selected {
  color: #fff !important;
  background-color: #117986 !important;
} */

#profile .flagged {
  border: red 2px solid;
}
#profile .profile-main .your-listings .btns {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 3rem auto 0;
}
#profile .profile-main .your-listings .btns div {
  color: #153e4d;
}
#profile .profile-main .your-listings .btns button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(233, 68, 3);
  border-color: rgb(233, 68, 3);
  color: #fff;
  padding: 10px 3rem;
  border-radius: 30px;
  margin-bottom: 1rem;
}
#profile .profile-main .your-listings .btns button svg {
  margin-right: 10px;
  font-size: 20px;
}
#profile .profile-main .payment-methods {
  background-color: #fff;
  padding: 0px;
  margin-left: 3rem;
  color: #153e4d;
}
#profile .profile-main .payment-methods .header {
  background-color: #f2f3f3;
  text-align: start;
  width: 100%;
}
#profile .profile-main .payment-methods .header div {
  width: 33%;
  display: inline-block;
  padding: 1rem 2rem;
  text-align: center;
}
.selected-billing {
  background-color: #fff;
}
#profile .profile-main .payment-methods .calendar {
  display: flex;
  justify-content: space-around;
  color: #153e4d;
  margin-top: 3rem;
}
#profile .profile-main .payment-methods .calendar .previous,
#profile .profile-main .payment-methods .calendar .next {
  background-color: #ddd;
  padding: 5px 20px;
  font-size: 14px;
}
#profile .profile-main .payment-methods .calendar .previous svg,
#profile .profile-main .payment-methods .calendar .next svg {
  margin: 0px 10px;
}
#profile .profile-main .payment-methods .items {
  padding: 0px 2rem;
}
#profile .profile-main .payment-methods .items .item-header div,
#profile .profile-main .payment-methods .items .item div {
  display: inline-block;
  width: 25%;
  text-align: start;
  font-size: 13px;
}
#profile .profile-main .payment-methods .items .item-header {
  border-bottom: 1px solid #879da5;
  padding: 2rem 2rem 0px;
}
#profile .profile-main .payment-methods .items .item-header div {
  padding: 0px;
}
#profile .profile-main .payment-methods .items .item {
  border-bottom: 1px solid #879da5;
  padding: 0px 2rem;
}
#profile .profile-main .payment-methods .items .item div {
  padding: 1rem 0px;
}
#profile .profile-main .payment-methods .items .item:last-child {
  border-bottom: none;
}
#profile .profile-main .payment-methods .billing-details-wrapper {
  text-align: start;
  padding: 2rem;
  width: 100%;
}
#profile .profile-main .payment-methods .billing-details-wrapper h3 {
  font-size: 22px;
  border-bottom: #ccc 1px solid;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

#profile .profile-main .payment-methods .billing-details-wrapper button {
  background-color: rgb(0, 94, 0);
  border-color: rgb(0, 94, 0);
  color: #fff;
  padding: 10px 3rem;
}
#profile .profile-main .payment-methods .payment-methods-wrapper {
  padding: 2rem;
}
#profile .profile-main .payment-methods .payment-methods-wrapper h3 {
  border-bottom: #ccc 1px solid;
  padding-bottom: 2rem;
  margin: 2rem 0px;
  font-size: 20px;
}

#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card {
  display: flex;
  align-items: center;
  justify-content: start;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card
  .card-text {
  margin-right: 20px;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card
  .ending {
  font-size: 12px;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card
  .status {
  font-size: 10px;
}

#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card
  img {
  width: 150px;
}

#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card
  .remove-card {
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
  padding: 5px;
  text-align: start;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .existing-cards
  .existing-card
  .remove-card
  .text {
  margin-left: 10px;
  width: 80%;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .add-new-cards {
  padding: 0px 2rem;
  text-align: start;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .add-new-cards
  .half-width-inputs
  div {
  width: 48%;
  margin-right: 5px;
  display: inline-block;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .add-new-cards
  button {
  width: 100%;
  font-size: 1.4rem;
  padding: 5px;
  background-color: rgb(0, 94, 0);
  border-color: rgb(0, 94, 0);
  color: #fff;
  margin-bottom: 1rem;
}
#profile
  .profile-main
  .payment-methods
  .payment-methods-wrapper
  .add-new-cards
  .info {
  font-size: 12px;
}
#profile .profile-main .account-information,
#profile .profile-main .become-dealer {
  text-align: start;
  padding: 2rem;
  color: #757c79;
}

#profile .profile-main .account-information h2,
#profile .profile-main .become-dealer h2 {
  font-size: 20px;
  border-bottom: #ccc 1px solid;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  color: #153e4d;
  font-weight: bold;
  text-transform: uppercase;
}
#profile .profile-main .account-information h3,
#profile .profile-main .become-dealer h3 {
  font-size: 18px;
  border-bottom: #ccc 1px solid;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
#profile .profile-main .choose-photos-header {
  width: 100%;
}
#profile .profile-main .photos {
  width: 100%;
}
#profile .profile-main .photos .photo img {
  height: 200px;
  /* width: 250px; */
}
#profile .profile-main .account-information .upload-photos,
#profile .profile-main .become-dealer .upload-photos {
  padding: 1rem;
  margin: 0 auto;
}
#profile .profile-main .account-information .upload-photos label,
#profile .profile-main .become-dealer .upload-photos label {
  margin: 1rem auto;
  float: left;
}
#profile .profile-main .account-information .upload-photos label .icon,
#profile .profile-main .become-dealer .upload-photos label .icon {
  background-color: #ddd;
  color: rgb(155, 154, 154);
  padding: 10px 40px;
}
#profile .profile-main .account-information .upload-photos label .icon svg,
#profile .profile-main .become-dealer .upload-photos label .icon svg {
  color: rgb(155, 154, 154);
  font-size: 100px;
  margin-right: 10px;
}
#profile .profile-main .account-information .upload-photos label .text,
#profile .profile-main .become-dealer .upload-photos label .text {
  background-color: rgb(9, 165, 9);
  color: #fff;
  padding: 10px;
  width: 80%;
  margin: 2rem auto;
}
#profile .profile-main .account-information .upload-photos label .text svg,
#profile .profile-main .become-dealer .upload-photos label .text svg {
  margin-right: 10px;
}
@media only screen and (max-width: 1500px) {
  #profile .profile-main .your-listings .wav-actions div.wav-length,
  #profile .profile-main .your-listings .wav-actions div.credits {
    margin: 0 10px 0 0;
    width: 200px;
    height: 100px;
    padding: 20px 10px 0px;
  }
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.wav-length
    .action-header,
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.credits
    .action-header {
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.wav-length
    .action-detail,
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.credits
    .action-detail {
    font-size: 40px;
  }
  #profile .profile-main .your-listings .wav-actions .wav-create {
    width: 200px;
    height: 100px;
    font-family: "Open Sans", " Montserrat", sans-serif;

    font-size: 17px;
    line-height: 1.5;
  }
  #profile .profile-main .your-listings .wav-actions .wav-create img {
    height: 60px;
  }
}
@media only screen and (max-width: 1300px) {
  .img_row1 img{
    height: 159px
  }
  #profile .profile-main .your-listings .wav-actions div.wav-length,
  #profile .profile-main .your-listings .wav-actions div.credits {
    margin: 0 10px 0 0;
    width: 170px;
    height: 100px;
    padding: 20px 10px 0px;
  }
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.wav-length
    .action-header,
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.credits
    .action-header {
    font-size: 15px;
    line-height: 1.2;
  }
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.wav-length
    .action-detail,
  #profile
    .profile-main
    .your-listings
    .wav-actions
    div.credits
    .action-detail {
    font-size: 30px;
  }
  #profile .profile-main .your-listings .wav-actions .wav-create {
    width: 170px;
    height: 100px;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #profile .profile-main .your-listings .wav-actions .wav-create img {
    height: 50px;
  }
}
@media (max-width:1199px){
  .col-md-3.img_row1{
    flex: 0 0 33%;
    max-width: 33%;
  }
}
@media only screen and (max-width: 1100px) {
  #profile .profile-main .payment-methods .items .item-header div,
  #profile .profile-main .payment-methods .items .item div {
    width: 50%;
  }
  #profile .profile-main .payment-methods .billing-details-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  #profile .profile-header .wrapper .text-wrapper {
    margin-top: 3rem;
  }
  #profile .profile-main .dashboard-side div.dashboard {
    text-align: start;
  }
  #profile .profile-main .dashboard-side div {
    font-size: 14px;
    text-align: start;
    margin: 0px;
  }
  #profile .profile-main .payment-methods .header div {
    width: 50%;
  }
  #profile .profile-main .dashboard-side .dashboard-side-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #profile .profile-main {
    width: 96%;
    margin: 2rem auto;
  }
  #profile .profile-main .dashboard-main {
    margin-top: 2rem;
  }
  #profile .profile-main .dashboard-side h3.dashboard {
    text-align: start;
    font-size: 18px;
    margin-left: 4rem;
    margin-bottom: 1rem;
  }

  #profile .profile-main .dashboard-side div {
    font-size: 14px;
    text-align: start;
    margin: 0px;
  }
  #profile .profile-main .dashboard-main,
  #profile .profile-main .payment-methods {
    margin-left: 0px;
  }
  #profile .profile-main .your-listings .header h3 {
    font-size: 18px;
  }
  #profile .profile-main .your-listings .btns button {
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 1rem;
    border-radius: 30px;
    margin-bottom: 1rem;
  }
  #profile .profile-main .payment-methods .header div {
    width: 100%;
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px) {
  #profile
    .profile-main
    .payment-methods
    .payment-methods-wrapper
    .add-new-cards
    .half-width-inputs
    div {
    width: 100%;
  }
}
