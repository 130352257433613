.App {
  text-align: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f2f3f3;
}

.main-layout {
  /* min-height: 100vh; */
  width: 100%;
  background-image: url("/public/assets/bgmain.png");
  background-image: url("/public/assets/Group_13370.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.section {
  min-height: 100vh;
  width: 100%;
  background-color: #f2f3f3;
}

#wrapper {
  width: 100%;
  text-align: center;
  margin: 50px;
}

#info {
  display: inline-block;
  text-align: left;
}

.coming-page h4 {
  margin-top: 13%;
  padding: 5% 0px;
}