@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;700&display=swap");
/* TOP NAV */

.top-nav {
  padding: 8px 245px 8px 240px;
  background-color: #004f6e;
  color: #fff;
}

.main-nav {
  position: relative;
  z-index: 1030;
}

.top-nav {
  position: relative;
  z-index: 1060;
}

.top-nav .wrapper {}

.top-nav .wrapper .contact-call {
  padding: 0px;
  display: flex;
  align-items: center;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.top-nav .wrapper .contact-call svg {
  border: 1px solid white;
  border-radius: 50%;
  padding: 2px;
  font-size: 15px;
}

.top-nav .wrapper .contact-call a {
  color: #eeeeee;
  text-decoration: none;
  margin: 10px;
}

.top-nav .wrapper .contact-call a:hover {
  color: #fff;
}

.top-nav .wrapper .compare-nav {
  display: flex;
  align-items: center;
}

/* .top-nav .wrapper .compare div.icon {
  margin-right: 8px;
} */

.top-nav .wrapper .compare-nav .login svg {
  color: #eeeeee;
  font-size: 27px;
  margin: 5px 0px 5px 15px;
}

.top-nav .wrapper .compare-nav .login svg:hover {
  color: #b6aeae;
}

/* MAIN NAV */
.main-nav {
  /* background-color: #004f63; */
  /* background-color: transparent; */
  width: 100%;
  font-family: "Montserrat", OpenSans;
}

.main-nav .wrapper {
  width: 80%;
  margin: 0 auto;
}

nav::after {
  content: "";
  clear: both;
  display: table;
}

.menu-icon {
  display: none;
}



nav.main-navbar {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

nav.main-navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto 10px;
  position: relative;
}

nav.main-navbar ul li {
  text-transform: uppercase;
  z-index: 10000;
  display: inline-block;
  list-style: none;
  margin: 20px 20px 20px 22px;
  color: #fff;
}

nav.main-navbar ul li#navbar-title {
  margin-right: 8rem;
}

nav.main-navbar ul li a {
  text-decoration: none;
  color: #fff;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0.22px;
  text-align: left;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}

nav.main-navbar ul li a:hover {
  color: #dc562c;
}

/* nav.main-navbar ul ul#dropdown {
  position: absolute;
  top: 100%;
  display: none;
  transition: top 0.3s;
  color: #ddd;
  text-align: start;
  background-color: #153e4d !important;
}
nav.main-navbar ul li:hover > ul#dropdown {
  display: flex;
  flex-direction: column;
  top: 50px;
  left: 0;
  right: 0; */
/* margin-left: -4rem; */
/* padding-top: 10px;
} */

nav.main-navbar ul div#dropdown {
  position: absolute;
  top: 100%;
  display: none;
  transition: top 0.3s;
  color: #ddd;
  text-align: start;
  /* background-color: #153e4d !important; */
}

nav.main-navbar ul li:hover>div#dropdown {
  display: flex;
  flex-direction: column;
  background-color: none;
  top: 60px;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
  /* margin-left: -4rem; */
  padding-top: 30px;
}

/* 
nav.main-navbar ul ul li {
  list-style: none;
  padding: 10px 20px;
  margin: 0;
  text-align: center;
  width: 20%;
} */

/* nav.main-navbar ul ul li:hover {
  color: #fff;
  border-top: 1px solid #023242;
  border-bottom: 1px solid #023242;
} */

nav.main-navbar ul li.my-auto {
  margin: 0px 0px 0px 5rem;
}

nav.main-navbar ul li.my-auto a.sell-your-wav {
  margin: 0 0 0 41px;
  padding: 15.2px 36px 19.9px 27px;
  border-radius: 40.5px;
  background-color: #dc562c;
  padding: 20.2px 46px 24.9px 37px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  text-align: left;
  color: #fff;
}

nav.main-navbar ul li.my-auto a.sell-your-wav svg {
  font-size: 27px;
  margin-right: 10px;
}

.main-nav .wrapper .logo img {
  max-width: 250px;
  max-height: 50px;
  font-weight: bold;
  color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: none;
}

nav.main-navbar ul li#navbar-title {
  /* margin-top: 2rem; */
  /* margin-right: 10rem; */
}

/* MENU */
/* li#buy div {
  position: absolute;
  top: 90px;
  opacity: 0;
  visibility: hidden;
  transition: top 0.3s;
}
li#buy:hover > div {
  top: 70px;
  opacity: 1;
  visibility: visible;
} */

/* DROPDOWN */
.dropdown-buy {
  position: relative;
  width: 100%;
}

.dropdown-wrapper {
  width: 100%;
  padding-bottom: 10px;
}

.dropdown-wrapper .row {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
}

.dropdown-wrapper .row .tabs {
  background-color: #004f63;
  border-left: 5px;
  margin: 0;
  padding: 5px;
}

.dropdown-wrapper .row .tabs ul {
  /* height: 100%; */
  padding: 0px;
  width: 100%;
  flex-direction: column;
}

.dropdown-wrapper .row .tabs ul li .icon svg {
  /* transform: rotate(90deg); */
  transition: .3s ease;
}

.dropdown-wrapper .row .tabs ul li.activemenu .icon svg {
  transform: rotate(90deg);
  transition: .3s ease;
}

.dropdown-wrapper .row .tabs ul li {
  list-style: none;
  padding: 10px;
  margin: 0;
  /* height: 20%; */
  color: #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-wrapper .row .tabs ul li.last {
  /* margin-bottom: 10px; */
}

.dropdown-wrapper .row .tabs ul li:hover {
  background-color: rgb(206, 118, 36);
  border-radius: 5px;
}

/* .dropdown-wrapper .tabs-info {
  border: 1px solid #004f6e;
} */
/* TRENDING */
.trending ul#trending-nav {
  background-color: #fff;
  color: #004f63;
  border-bottom: 1px solid #004f6e;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  text-align: start;

  /* padding: 20px 20px 0px; */
}

.trending ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: start;
}

.trending ul li a {
  text-decoration: none;
  color: #004f6e !important;
  text-align: start;
}

.trending ul li a:hover {
  padding-bottom: 10px;
  border-bottom: rgb(206, 118, 36) 1px solid;
}

.offers {
  display: flex;
  justify-content: start;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.offers .card,
.wav-type .card,
.all-makes .make {
  border: none;
}

.offers .card img {
  height: 140px;
  width: 100%;
}

.offers .card-body {
  text-decoration: none;
  color: #333;
  padding: 10px 0px 0px;
  background: #fff;
}

.offers .card-body a.card-title {
  text-decoration: none;
  color: #333;
  padding: 0;
  margin: 0;
}

.image-wrapper {
  position: relative;
  margin-top: 0px !important;
}

.image-wrapper img {
  margin-top: 0px !important;
  width: 100% !important;
  height: auto !important;
  max-width: 100px;
}

.price {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #00badb;
  padding: 2px 5px;
  font-size: 12px;
  color: #fff;
  font-weight: bolder;
}

/* WAY-TYPE */
.wav-type .card {
  padding-bottom: 5px;
}

.wav-type .card-body {
  text-decoration: none;
  color: #004f6e;
  padding: 10px 0px 0px;
  background: #fff;
}

.wav-type .card-body a.card-title {
  text-decoration: none;
  color: #004f6e !important;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.wav-type h6 {
  color: #004f6e !important;
  text-align: start;
  font-weight: bold;
  margin-bottom: 1.1rem;
}

.wav-type .list div {
  display: flex;
  align-items: flex-start;
}

.wav-type .list div ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wav-type .list ul li {
  list-style: none;
  padding: 0px;
  margin: 0;
}

.wav-type .list ul li a {
  text-decoration: none;
  color: #333;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.wav-type .list ul li a:hover {
  color: #000;
}

.wav-type .ads {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.wav-type .ads .ad {
  width: 100%;
  margin: 5px;
}

.wav-type .ads .ad:hover {
  background-color: #004f6e;
}

.wav-type .ads img {
  max-height: 130px;
  width: 100%;
  padding: 20px;
}

.wavtype-wrapper {
  height: 80%;
  border-right: 1px solid #ccc;
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
}

.wavtype-wrapper div {
  cursor: pointer;
  /* width: 30%; */
  margin: 2px 5px;
  padding: 5px;
}

.wavtype-wrapper img {
  height: 70px;
  width: 70px;
}

.wav-type ul li::before {
  content: "\2022";
  color: green;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* ALL-MAKES  */
.all-makes .cards {
  display: flex;
  flex-wrap: wrap;
}

.all-makes h6 {
  text-align: start;
  font-weight: bold;
  padding: 5px;
  color: #004f6e;
  margin-bottom: 1.2rem;
}

.all-makes .cards .make {
  height: 80%;
  margin: 10px 10px;
}

.all-makes .cards .make img {
  width: 70px;
  height: 70px;
}

.all-makes .cards .make:last-child {
  margin-top: -5px;
}

.all-makes .cards .make:last-child img {
  height: 100%;
  width: 100%;
}

.all-makes .card-body {
  padding: 5px;
  margin: 0;
}

.all-makes .card-body a.card-title {
  text-decoration: none;
  color: #333;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

/* SELLER  */
.seller {
  font-family: "Montserrat", "Open Sans", sans-serif;
  color: #004f6e;
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  padding: 10px;
  text-transform: none;
}

.seller div.text h1 {
  font-size: 25px;
  width: 80%;
  font-weight: bold;
}

.seller div.text h4 {
  font-size: 17px;
  color: red;
  width: 80%;
  margin-top: 2rem;
}

.seller div.text h1.longer,
.seller div.text h4.longer {
  width: 100%;
}

.seller div.text h4.contact {
  color: #000;
  width: 100%;
}

.seller div.text button {
  border: none;
  background-color: white;
  color: #004f6e;
  margin-top: 2rem;
  font-size: 17px;
  font-weight: bold;
}

.seller div.text button:hover {
  color: #fff;
  background-color: #004f6e;
  padding: 10px;
}

.seller div.img {
  text-align: end;
}

.seller div.img img {
  /* height: 300px;
  width: 100%;
  object-fit: cover; */
}

@media only screen and (max-width: 1900px) {
  .top-nav {
    padding: 8px 135px 8px 135px;
    background-color: #004f6e;
    color: #fff;
  }

  .main-nav .wrapper {
    width: 85%;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  nav.main-navbar ul li#navbar-title {
    margin-right: 7rem;
  }

  nav.main-navbar ul li a {
    font-size: 16px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0 0 0 31px;
    padding: 15.2px 46px 17.9px 37px;
    font-family: "Montserrat", "Open Sans", sans-serif;

    font-size: 16px;
  }

  .main-nav .wrapper .logo {
    font-weight: bold;
    color: #fff;
    font-size: 28px;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1740px) {
  nav.main-navbar ul li#navbar-title {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 1650px) {
  .top-nav {
    padding: 8px 80px 8px 80px;
    background-color: #004f6e;
    color: #fff;
  }

  .main-nav .wrapper {
    width: 90%;
  }

  nav.main-navbar ul li a {
    font-size: 15px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0 0 0 31px;
    padding: 15.2px 26px 20.9px 17px;
    font-family: "Montserrat", "Open Sans", sans-serif;

    font-size: 15px;
  }

  .main-nav .wrapper .logo {
    font-weight: bold;
    color: #fff;
    font-size: 25px;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1525px) {
  nav.main-navbar ul li#navbar-title {
    margin-right: 2rem;
  }

  nav.main-navbar ul li a {
    font-size: 14px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0 0 0 21px;
    padding: 10.2px 16px 15.9px 10px;
    font-family: "Montserrat", "Open Sans", sans-serif;

    font-size: 14px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav svg {
    font-size: 23px;
    margin-right: 10px;
  }

  .main-nav .wrapper .logo {
    font-weight: bold;
    color: #fff;
    font-size: 23px;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1400px) {
  .top-nav {
    padding: 8px 40px 8px 40px;
    background-color: #004f6e;
    color: #fff;
  }

  .main-nav .wrapper {
    width: 100%;
  }

  nav.main-navbar ul li#navbar-title {
    margin-right: 5px;
  }

  nav.main-navbar ul li a {
    font-size: 14px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0 0 0 10px;
    padding: 10.2px 16px 15.9px 10px;

    font-size: 14px;
  }

  .main-nav .wrapper .logo {
    font-weight: bold;
    color: #fff;
    font-size: 22px;
    text-decoration: none;
  }

  .dropdown-wrapper .row .tabs ul li {
    padding: 5px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1240px) {
  .all-makes .cards .make {
    width: 15%;
  }

  .all-makes .card-body a.card-title {
    display: none;
  }

  .top-nav .wrapper .contact-call {
    font-size: 13.5px;
  }

  nav.main-navbar ul li#navbar-title {
    margin-right: 0rem;
  }

  nav.main-navbar ul li a {
    font-size: 13px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0 0 0 0px;
    padding: 10.2px 16px 15.9px 10px;
    font-size: 13px;
  }

  .main-nav .wrapper .logo {
    font-weight: bold;
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 18px;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1160px) {
  .dropdown-wrapper {
    padding-bottom: 10px;
  }

  .top-nav .wrapper .contact-call {
    font-size: 13px;
  }

  nav.main-navbar ul li a {
    font-size: 12px;
  }

  nav.main-navbar ul li {
    margin: 18px 13px 16px 15px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0 0 0 0px;
    padding: 10.2px 8px 15.9px 8px;
    font-size: 12px;
  }
}

.flex_menu1 span {
  display: none;
}

.num2 svg {
  display: none;
}

@media only screen and (max-width: 1020px) {
  .mob_logo_none {
    display: none !important;
  }



  .flex_menu1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-left: 25px;
  }

  .top-nav {
    padding: 8px 4px 8px 0px;
  }

  .main-nav {
    overflow: auto;
  }

  nav.main-navbar ul li:hover>div#dropdown {
    display: none;
  }

  nav.main-navbar ul .hidedropdown div#dropdown {
    padding-top: 20px !important;
    margin: 0 auto;
    width: 90%;
  }

  .main-nav .wrapper {
    width: 100%;
    margin: 0 auto;
  }

  nav.main-navbar ul {
    flex-direction: column;
    align-items: flex-start;
  }

  nav.main-navbar ul div#dropdown {
    position: static;
    width: 100%;
    margin: 0;
    padding-top: 0;
    text-align: start;
  }

  nav.main-navbar ul ul li {
    padding: 10px 20px;
    margin: 0;
    text-align: start;
  }

  .wav-type .card-body a.card-title {
    text-decoration: none;
    color: #333;
    padding: 0;
    margin: 0;
    font-size: 8px;
  }

  .all-makes .card-body a.card-title {
    display: none;
  }

  .top-nav {
    margin-bottom: 0 !important;
  }

  /* MAIN NAV */
  .main-nav {
    margin: 0;
    font-family: "Montserrat", sans-serif;
  }

  .main-nav .wrapper {
    background-color: #153e4d !important;
    display: none;
    text-align: start;
    /* position: absolute;
    top: 5vh; */
    /* overflow: hidden; */
    margin: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .main-nav .wrapper .logo {
    margin-left: 20px;
  }

  .flex_menu1 span {
    display: inline-block;
  }

  .menu-icon {
    display: block;
    /* background-color: #004f63 !important; */
    text-align: end;
    padding: 10px 20px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }

  .main-nav span img {
    max-width: 220px;
  }

  .menu-icon svg {
    color: #fff;
  }

  nav.main-navbar ul li {
    padding: 10px 0px;
    display: block;
    text-align: start;
  }

  nav.main-navbar ul li.my-auto {
    padding: 0px 0px 50px 0px;
    text-align: left;
    margin-left: 20px;
  }

  nav.main-navbar ul li.my-auto a.sell-your-wav {
    margin: 0px;
    /* float: left; */
    text-align: left;
  }

  nav.main-navbar ul li#navbar-title {
    /* margin-top: 2rem; */
    /* margin-right: 3rem; */
  }

  .dropdown-wrapper {
    position: relative;

    /* left: 50%;
    transform: translate(-50%, 0); */
    padding-bottom: 10px;
  }

  .trending ul#trending-nav {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
  }

  .trending ul li a {
    display: inline-block;
  }

  .trending div.card .card-title {
    font-size: 12px;
  }

  .wav-type .list div ul {
    justify-content: space-between;
  }

  .wav-type .list ul li a {
    font-size: 12px;
  }

  .wav-type .ads .ad img {}

  .wavtype-wrapper {
    margin: 0 auto;
    border-right: none;
  }

  .wavtype-wrapper img {
    height: 50px;
    width: 50px;
  }
}

.seller div.img {
  padding-left: 16px;
}

@media (max-width:991px) {

  .private-seller .text,
  .trade-seller .text,
  .wheelchair-bus .text {
    width: 40%;
  }

  .disabled-motoring .text,
  .contact-us .text {
    width: 60%;
  }

  .private-seller .seller div.img,
  .trade-seller .seller div.img,
  .wheelchair-bus .seller div.img {
    width: 80%;
  }

  .private-seller .text,
  .trade-seller .text {

    padding-right: 11px;

  }

  .seller div.text button,
  .trade-seller div.text button,
  .wheelchair-bus div.text button {
    margin-top: 1rem;
  }

  .seller div.text h1,
  .trade-seller .seller .text h1,
  .wheelchair-bus .seller .text h1 {
    width: 100%;
  }

  .seller div.text h4,
  .trade-seller div.text h4,
  .wheelchair-bus div.text h4 {
    margin-top: 1rem;
    width: 100%;
  }

  .all-makes .cards .make {
    width: 30%;
    margin: 10px 0px;
    text-align: center;
  }

  .wav-type-menu .wavtype-wrapper div {
    width: 30%;
    text-align: center;
    margin: 0;
  }

  .dropdown-wrapper .row {
    justify-content: center;
  }

  .wav-type-menu .row {
    margin: auto;
  }

  .wav-type-menu .wav-type .col-md-12 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .top-nav .wrapper .contact-call {
    font-size: 12px;
  }
}



@media only screen and (max-width: 600px) {
  .flex_menu1 span img {
    max-width: 165px;
  }

  .num2 svg {
    display: inline-block;
  }

  .private-seller .text,
  .trade-seller .text,
  .wheelchair-bus .text {
    width: 100%;
  }

  .seller div.text h1,
  .wheelchair-bus div.text h1 {
    font-size: 18px;
  }

  .seller div.text h4,
  .wheelchair-bus div.text h4 {
    font-size: 14px;

  }

  .top-nav {
    padding: 8px 10px 8px 10px;
    background-color: #004f6e;
    color: #fff;
  }

  .top-nav .wrapper .contact-call {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
    align-items: start;
  }

  .top-nav .wrapper .contact-call a {
    margin: 5px;
    font-size: 11px;
  }

  .top-nav .wrapper .contact-call span {
    display: none;
  }

  .top-nav .wrapper .compare-nav {
    display: block;
    width: 30%;
    text-align: right;
  }

  .top-nav .wrapper .compare-nav login {
    float: right;
    text-align: right;
    margin: 0;
    padding: 0;
  }

  .top-nav .wrapper .compare-nav .login svg {
    font-size: 25px;
  }

  .all-makes-menu .image-wrapper img,
  .wav-type-menu .wavtype-wrapper div img {
    max-width: 65px;
  }

  .private-seller .seller,
  .trade-seller .seller,
  .wheelchair-bus .seller,
  .disabled-motoring .seller,
  .contact-us .seller {
    flex-direction: column;
  }

  .private-seller .seller div.img,
  .trade-seller .seller div.img,
  .wheelchair-bus .seller div.img {
    margin-top: 10px;
    width: 100%;
  }

  .seller div.img {
    padding-left: 0;
  }

  .disabled-motoring .text,
  .contact-us .text {
    width: 100%;
  }
}