%transition {
  transition: 0.12s;
}
.buttons {
  // margin-top: 5px;
  padding: 5px;
  width: 96%;
  margin: 5px auto 0;
}
.buttons a {
  text-decoration: none;
}
.buttons a span {
  padding: 7px 20px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.56px;
  color: #fff;
}
.price-btn {
  @extend %transition;
  display: inline-block;
  padding: 10px 30px 10px 0px;
  text-decoration: none;
  color: #fff;
  position: relative;
  width: 40%;
  margin-right: 5px;
  text-align: start;
  > span {
    position: relative;
    z-index: 5;
    text-align: left;
  }

  &:after {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #28b8cb;
    border-width: 3px 3px 3px 0;
    transform: skewX(-25deg);
    background-color: #28b8cb;
  }

  &:before {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    bottom: 0;
    width: 28px;
    border: solid 1px #28b8cb;
    border-width: 3px 0 3px 3px;
    background-color: #28b8cb;

    z-index: 8;
  }
}

.price-btn:hover,
.price-btn:focus {
  color: #fff;

  &:after {
    background: #1dbcd1;
  }

  &:before {
    background: #1dbcd1;
  }
}

.enquire-btn {
  @extend %transition;
  display: inline-block;
  padding: 10px 5px 10px 15px;
  text-decoration: none;
  color: #fff;
  position: relative;
  text-align: end;
  width: 50%;
  margin-left: 15px;
  > span {
    position: relative;
    text-align: end;
    z-index: 5;
  }

  &:after {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #dc562c;
    border-width: 3px 3px 3px 0;
    background-color: #dc562c;
  }

  &:before {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    bottom: 0;
    width: 30px;
    border: solid 1px #dc562c;
    border-width: 3px 0 3px 3px;
    background-color: #dc562c;
    z-index: 8;
    transform: skewX(-25deg);
  }
}

.enquire-btn:hover,
.enquire-btn:focus {
  color: #fff;

  &:after {
    background: #e45023;
  }

  &:before {
    background: #e45023;
  }
}

@media only screen and (max-width: 1750px) {
  .buttons a span {
    padding: 5px 17px;
    font-size: 14px;
    line-height: 1.2;
  }
  .price-btn {
    padding: 8px 40px 8px 0px;
    width: 39%;
  }

  .enquire-btn {
    padding: 8px 5px 8px 5px;
    width: 51%;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 1610px) {
  .buttons {
    padding: 0px;
    width: 95%;
    margin-left: 13px;
  }

  .price-btn {
    padding: 8px 40px 8px 0px;
    width: 39%;
  }

  .enquire-btn {
    padding: 8px 5px 8px 5px;
    width: 52%;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 1400px) {
  .buttons {
    padding: 0px;
    width: 95%;
    margin-left: 13px;
  }

  .buttons a span {
    padding: 5px 12px;
    font-size: 13px;
    line-height: 1;
  }
  .price-btn {
    padding: 8px 40px 8px 0px;
  }

  .enquire-btn {
    padding: 8px 5px 8px 15px;
  }
}

@media only screen and (max-width: 1310px) {
  .buttons {
    padding: 0px;
    width: 95%;
    margin-left: 13px;
  }

  .buttons a span {
    padding: 5px 8px;
    font-size: 13px;
    line-height: 1;
    text-align: center;
  }
  .price-btn {
    padding: 6px 30px 6px 0px;
    width: 35%;

    &:before {
      left: -10px;
      width: 20px;
    }

    .enquire-btn {
      padding: 6px 0px 6px 0px;
      width: 50%;
      margin-left: 5px;
      &:before {
        left: -12px;
        width: 25px;
      }
    }
  }
}
@media only screen and (min-width: 985px) and (max-width: 1210px) {
  .buttons {
    padding: 0px;
    width: 100%;
    margin-left: 5px;
  }
  .buttons a {
    padding: 5px 0px;
    margin-right: 5px;
  }
  .buttons a span {
    padding: 5px 8px;
    font-size: 12px;
    line-height: 1;
    text-align: end;
  }
  .price-btn {
    padding: 6px 30px 6px 0px;
    width: 30%;

    &:before {
      left: -10px;
      width: 20px;
    }

    .enquire-btn {
      padding: 6px 0px 6px 0px;
      width: 50%;
      margin-left: 5px;
      &:before {
        left: -12px;
        width: 25px;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .buttons a span {
    padding: 5px 5px;
    font-size: 12px;
  }
  .price-btn {
    padding: 6px 10px 6px 5px;

    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .enquire-btn {
    padding: 6px 0px 6px 0px;
    &:before {
      left: -12px;
      width: 25px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .buttons a span {
    font-size: 11px;
  }
  .price-btn {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: center;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);

    > span {
      text-align: center;
    }

    &:after {
      left: 0;
      border: solid 1px #28b8cb;
      border-width: 0px 0px 0px 0;
      transform: skewX(0deg);
    }

    &:before {
      left: 0px;
      bottom: 0;
      width: 0px;
      border-width: 0px 0 0px 0px;
    }
  }

  .enquire-btn {
    margin-right: 0px;
    text-align: center;
    width: 100%;
    margin-left: 0px;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);
    > span {
      position: relative;
      text-align: end;
      z-index: 5;
    }

    &:after {
      border-width: 0;
    }

    &:before {
      left: 0px;
      width: 0px;
      border-width: 0px;
      transform: skewX(0deg);
    }
  }
}
