.featured {
  padding: 60px 0px;
}

.featured h1 {
  color: #004f6e;
  font-weight: bold;
  margin: 30px 0px 10px;
}

.featured button {
  background-color: red;
  border: red;
  padding: 12px;
  border-radius: 24px;
  color: #fff;
  margin: 30px 0px;
}
