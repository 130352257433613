#dmuk {
  margin: 10rem 0 0;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
  font-family: "Montserrat", "Open Sans", sans-serif;
}

#dmuk .colored-header {
  background-color: rgb(253, 102, 1);
}

#dmuk .colored-header .text {
  width: 70%;
  font-size: 2.5rem;
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
  padding: 1rem 2rem;
}

#dmuk .main-content {
  width: 70%;
  margin: 3rem auto;
}

#dmuk .main-content div {
  margin-bottom: 2rem;
}

#dmuk .main-content .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

#dmuk .main-content .logo img {
  width: 250px;
  text-align: center;
}

#dmuk .main-content .find-out-more {
  text-align: center;
}

#dmuk .main-content .find-out-more a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  #dmuk {
    margin: 7rem 0 0;
  }

  #dmuk .colored-header .text {
    width: 90%;
    font-size: 1.5rem;
  }
}

@media (max-width:1199px) {
  #dmuk .colored-header .text {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 500px) {
  #dmuk .colored-header .text {
    padding-top: 3rem;
    width: 98%;
    font-size: 1.2rem;
  }

  #dmuk .colored-header .text {
    font-size: 1rem !important;
    padding-top: 2rem;
  }

  #dmuk .main-content {
    width: 85%;
    margin: 2rem auto;
  }

  .main-content img[alt="car"].mt-5 {
    margin-top: 0 !important;
  }
}