#profile .admin-main-header {
  padding: 20px;
  margin-top: 8rem;
  background-color: #ddd;
  color: #004f63;
  -webkit-box-shadow: 0px 0px 11px 0px #6b6b6b;
  box-shadow: 0px 0px 11px 0px #6b6b6b;
  margin-bottom: 3rem;
}
#profile .profile-main .admin-dashboard-side {
  color: #153e4d;
}
#profile .profile-main .admin-dashboard-side h3 {
  color: #fff;
  text-align: end;
  padding: 15px;
  /* margin-bottom: 3rem; */
  font-size: 20px;
  background-color: #153e4d;
}

#profile .profile-main .admin-dashboard-side div div {
  padding: 10px;
  text-align: end;
  cursor: pointer;

  margin-left: auto;
}
#profile .profile-main .admin-dashboard-side div div:hover {
  color: #153e4d;
  background-color: #fff;
  padding: 10px;
}
#profile .admin-main-header .content {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  margin: 0 auto;
}

#profile .admin-main-header .content .icon {
  margin-right: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}
#profile .profile-main .edit-dealer-listing {
  text-align: start;
  color: #757c79;
  background-color: #fff;
  padding: 0;
  margin-left: 3rem;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
#profile .profile-main .edit-dealer-listing .header {
  background-color: #153e4d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
#profile .profile-main .edit-dealer-listing .header h3 {
  font-size: 20px;
  margin-top: 30px;
  padding: 0px;
  border: none;
}
/* #profile .profile-main .edit-dealer-listing h2 {
  font-size: 20px;
  border-bottom: #ccc 1px solid;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  color: #153e4d;
  font-weight: bold;
} */
#profile .profile-main .edit-dealer-listing .edit-dealer-title {
  text-align: start;
  padding: 2rem 2rem 0px;
}
#profile .profile-main .edit-dealer-listing .row {
  padding: 0px 2rem;
}
#profile .profile-main .edit-dealer-listing h3 {
  font-size: 18px;
  border-bottom: #ccc 1px solid;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
#profile .profile-main .edit-dealer-listing .upload-photos {
  padding: 1rem;
  margin: 0 auto;
}
#profile .profile-main .edit-dealer-listing .upload-photos label {
  margin: 1rem auto;
  float: left;
}
#profile .profile-main .edit-dealer-listing .upload-photos label .icon {
  background-color: #ddd;
  color: rgb(155, 154, 154);
  padding: 20px 80px;
}
#profile .profile-main .edit-dealer-listing .upload-photos label .icon svg {
  color: rgb(155, 154, 154);
  font-size: 150px;
  margin-right: 10px;
}
#profile .profile-main .edit-dealer-listing .upload-photos label .text {
  background-color: rgb(9, 165, 9);
  color: #fff;
  padding: 10px;
  font-size: 1.2rem;
  text-align: center;
  width: 80%;
  margin: 2rem auto;
}
#profile .profile-main .edit-dealer-listing .upload-photos label .text svg {
  margin-right: 10px;
}
#profile .profile-main .edit-dealer-listing .btns {
  padding-bottom: 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
#profile .profile-main .edit-dealer-listing .btns button {
  padding: 10px 50px;
  margin: 2rem 20px 0px 0px;
  background-color: #757c79;
  border-color: #757c79;
  color: #fff;
  border-radius: 3px;
}

/* EDIT VEHICLE LISTING */
#profile .profile-main .edit-vehicle-listing {
  background-color: #fff;
  border-top-left-radius: 40px;
  margin-left: 3rem;
  border-top-right-radius: 40px;
}
#profile .profile-main .edit-vehicle-listing .header {
  background-color: #153e4d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 0px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

#profile .profile-main .edit-vehicle-listing .header h3 {
  font-size: 20px;
  margin-top: 5px;
}
#profile .profile-main .edit-vehicle-listing .header svg {
  margin-right: 2rem;
  font-size: 25px;
}
#profile .profile-main .edit-vehicle-listing .choose-photos {
  margin: 2rem 0;
  padding: 2rem;
}
#profile .profile-main .edit-vehicle-listing .choose-photos h3 {
  font-size: 16px;
  color: #153e4d;
  text-align: start;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #0b1611;
  padding-bottom: 5px;
}
#profile .profile-main .edit-vehicle-listing .upload-photos {
  padding: 1rem;
}
#profile .profile-main .edit-vehicle-listing .upload-photos label {
  margin: 1rem 0px;
  float: left;
}
#profile .profile-main .edit-vehicle-listing .upload-photos label .icon {
  background-color: #ddd;
  color: rgb(155, 154, 154);
  padding: 20px 40px;
}
#profile .profile-main .edit-vehicle-listing .upload-photos label .icon svg {
  color: rgb(155, 154, 154);
  font-size: 100px;
}
#profile .profile-main .edit-vehicle-listing .upload-photos label .text {
  background-color: rgb(9, 165, 9);
  color: #fff;
  padding: 10px;
  width: 80%;
  margin: 2rem auto;
}
#profile .profile-main .edit-vehicle-listing .upload-photos label .text svg {
  margin-right: 10px;
  font-size: 1.5rem;
  margin-top: -5px;
}
#profile .profile-main .edit-vehicle-listing .vehicle-details {
  text-align: start;
}
#profile
  .profile-main
  .edit-vehicle-listing
  .vehicle-details
  .form-group
  select.form-control {
  padding: 10px;
}
#profile .profile-main .edit-vehicle-listing .select-features {
  margin-top: 2rem;
}
#profile .profile-main .edit-vehicle-listing .select-features h3 {
  text-align: center;
  padding: 10px 0px 20px;
}
#profile .profile-main .edit-vehicle-listing .select-features .features {
  display: flex;
  flex-wrap: wrap;
}
#profile .profile-main .edit-vehicle-listing .select-features .features button {
  width: auto;
  padding: 5px 2rem;
  border-color: #117986;
  background-color: #fff;
  color: #117986;
  border-radius: 40px;
  margin: 10px 5px;
  font-size: 14px;
}
.selected {
  color: #fff;
  background-color: #117986;
}

#profile .profile-main .edit-vehicle-listing .btns {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 3rem auto 0;
}
#profile .profile-main .edit-vehicle-listing .btns div {
  color: #153e4d;
}
#profile .profile-main .edit-vehicle-listing .btns button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(233, 68, 3);
  border-color: rgb(233, 68, 3);
  color: #fff;
  padding: 10px 3rem;
  border-radius: 30px;
  margin-bottom: 1rem;
}
#profile .profile-main .edit-vehicle-listing .btns button svg {
  margin-right: 10px;
  font-size: 20px;
}
#profile
  .profile-main
  .edit-vehicle-listing
  .listing-details
  .upload-photos
  label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#profile
  .profile-main
  .edit-vehicle-listing
  .listing-details
  .upload-photos
  label
  .icon {
  margin-right: 10px;
}
#profile
  .profile-main
  .edit-vehicle-listing
  .listing-details
  .upload-photos
  label
  .btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#profile
  .profile-main
  .edit-vehicle-listing
  .listing-details
  .upload-photos
  label
  .btns
  .text {
  display: block;
  width: 100%;
  padding: 5px;
  margin: 0 auto;
}
#profile
  .profile-main
  .edit-vehicle-listing
  .listing-details
  .upload-photos
  label
  .btns
  .choose {
  background-color: #ddd;
  color: #000;
  padding: 5px;
  width: 100%;
  display: block;
  margin: 10px auto;
}
#profile .profile-main .dealer-listing,
#profile .profile-main .vehicle-listing {
  margin-left: 3rem;
}
#profile .profile-main .wav-listing {
  padding: 2rem 1rem;
}
#profile .profile-main .dealer-listing h2,
#profile .profile-main .vehicle-listing h2 {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #0b1611;
  padding-bottom: 5px;
  text-align: start;
}
#profile .profile-main .dealer-listing .search-and-next,
#profile .profile-main .vehicle-listing .search-and-next,
#profile .profile-main .wav-listing .search-and-next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#profile .profile-main .dealer-listing .prev-or-next,
#profile .profile-main .vehicle-listing .prev-or-next,
#profile .profile-main .wav-listing .prev-or-next {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

#profile .profile-main .dealer-listing .prev-or-next img,
#profile .profile-main .vehicle-listing .prev-or-next img,
#profile .profile-main .wav-listing .prev-or-next img {
  width: 30px;
  border: #ccc 1px solid;
  margin-right: 5px;
  padding: 5px;
}
#profile .profile-main .dealer-listing .prev-or-next .text,
#profile .profile-main .vehicle-listing .prev-or-next .text,
#profile .profile-main .wav-listing .prev-or-next .text {
  background-color: #fff;
  width: 80px;
  padding: 5px;
}
#profile .profile-main .dealer-listing h2,
#profile .profile-main .vehicle-listing h2 {
  color: #153e4d;
}
#profile .profile-main .dealer-listing .lists,
#profile .profile-main .vehicle-listing .lists,
#profile .profile-main .wav-listing .lists {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
#profile .profile-main .dealer-listing .lists div.list-item,
#profile .profile-main .vehicle-listing .lists div.list-item,
#profile .profile-main .wav-listing .lists div.list-item {
  margin-right: 2rem;
  cursor: pointer;
  color: #153e4d;
}
#profile .profile-main .dealer-listing .lists div.list-item:hover,
#profile .profile-main .vehicle-listing .lists div.list-item:hover,
#profile .profile-main .wav-listing .lists div.list-item:hover {
  text-decoration: underline;
}
#profile .profile-main .dealer-listing table tbody td img,
#profile .profile-main .vehicle-listing table tbody td img,
#profile .profile-main .wav-listing table tbody td img {
  width: 100px;
}
#profile .profile-main .dealer-listing .dealers-list .dealer-list,
#profile .profile-main .vehicle-listing .dealers-list .dealer-list,
#profile .profile-main .wav-listing .dealers-list .dealer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delWav {
  outline: none;
  border: 1px solid rgb(233, 68, 3);
  padding: 3px;
  border-radius: 10px;
}
@media only screen and (max-width: 1450px) {
  #profile .profile-main .edit-dealer-listing .upload-photos label .icon {
    padding: 20px 60px;
  }
  #profile .profile-main .edit-dealer-listing .upload-photos label .icon svg {
    font-size: 130px;
  }
  #profile .profile-main .edit-dealer-listing .upload-photos label .text {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1218px) {
  #profile .profile-main .edit-dealer-listing .upload-photos label .icon {
    padding: 20px 40px;
  }
  #profile .profile-main .edit-dealer-listing .upload-photos label .icon svg {
    font-size: 100px;
  }
  #profile .profile-main .edit-dealer-listing .upload-photos label .text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1000px) {
  #profile .profile-main .admin-dashboard-side h3 {
    font-size: 16px;
  }
  #profile .profile-main .admin-dashboard-side div {
    font-size: 14px;
    text-align: start;
    margin: 0px;
  }
}

@media only screen and (max-width: 769px) {
  #profile .profile-main {
    width: 94%;
    margin: 2rem auto;
  }
  #profile .profile-main .edit-dealer-listing,
  #profile .profile-main .edit-vehicle-listing {
    margin-left: 0rem;
  }
  #profile .profile-main .edit-dealer-listing .btns button {
    padding: 10px 30px;
    margin: 2rem 10px 0px 0px;
    border-radius: 3px;
  }
  #profile .profile-main .admin-dashboard-side .admin-dashboard-side-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #profile .profile-main .admin-dashboard-side h3 {
    text-align: start;
    font-size: 18px;
    margin-bottom: 1rem;
  }
  #profile .profile-main .admin-dashboard-side div {
    font-size: 14px;
    text-align: start;
    margin: 0px;
  }
  #profile .profile-main .dealer-listing .search-and-next {
    display: block;
  }
  #profile .profile-main .dealer-listing .search-and-next div {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 500px) {
  #profile .profile-main .edit-dealer-listing .btns {
    flex-direction: column;
  }
}
