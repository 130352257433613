.showcase-wrapper {
  /* background-color: white; */
  padding: 80px 0px 0px;
  /* border-radius: 5px; */
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.showcase-wrapper h1 {
  width: 80%;
  margin: 0 auto;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.52px;
  text-align: center;
  color: #232628;
}

.showcase-wrapper ul {
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.showcase-wrapper ul li {
  cursor: pointer;
  list-style: none;
  padding: 40px 20px;
}

.showcase-wrapper .showcase-navbar ul {
  margin-top: 30px;
  color: #133440;
  padding: 0px;
  margin-bottom: 0;
  justify-content: flex-start;
}

.showcase-wrapper .showcase-navbar ul li {
  border-bottom: #002e5b 1px dashed;
  padding: 10px 10px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: -0.44px;
  /* text-align: left; */
  color: #002e5b;
  /* padding-bottom: 5px; */
  margin-right: 30px;
}

.showcase-selected {
  border: none !important;
  border-radius: 30px;
  background-color: #fff;
}

div.nav-item a.nav-link {
  color: #0f4350;
}

.showcase-wrapper .upper-part {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 60%;
  margin-top: 80px;
  /* background-color: white; */
}

.showcase-wrapper .upper-part .types div {
  color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 50%;
  font-weight: bold;
  font-size: 16px;
  background-color: rgb(7, 204, 204);
  font-family: "Roboto", sans-serif;
}

.showcase-wrapper .upper-part .types div.passengers {
  border-top-left-radius: 5px;
}

.showcase-wrapper .upper-part .types div.drivers {
  border-top-right-radius: 5px;
}

.on-focus {
  background-color: #df4e24 !important;
}

.options {
  margin: 0;
  padding: 0;
  max-height: 117px;
  height: 100%;
}

.option-header span {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.6px;
  text-align: left;
  color: #232628;
}

.icon-options div {
  width: 50%;
  display: inline-block;
  text-align: start;
  margin: 0 auto;
  padding: 1px;
  color: #133440;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000;
}

.icon-options:last-child {
  padding-bottom: 30px;
  display: flex;
  border-bottom: #707070 1px solid;
}

.icon-options div span svg {
  padding-right: 10px;
  font-size: 33px;
  color: #002e5b;
}

.cards {
  width: 100%;
  padding-bottom: 50px;
  /* border-bottom: rgb(7, 204, 204) 2px solid; */
}

.cards button.btn-lg {
  margin-top: 4rem;
}

.cards .row .col-md-4 {
  /* width: 100%; */
  margin: 0 auto;
}

.wav-image {
  height: 280px;
  object-fit: cover;
  /* width: 100%; */
  margin: 0 auto;
}

.show-all {
  padding: 12px 42px 12px 43px;
  border-radius: 39px;
  box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #dc562c;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
}

.show-all:hover {
  color: #fff;
}

div.road span {
  /* background-color: #002e5b;
  border-radius: 100%;
  padding: 5px;
  margin-right: 5px; */
  margin-right: 5px;
}

div.road span svg {
  color: #fff !important;
  font-size: 16px !important;
  padding: 0 !important;
}

@media only screen and (max-width: 1800px) {
  .icon-options div {
    font-size: 17px;
  }
}

@media only screen and (max-width: 1600px) {
  .icon-options div {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1500px) {
  .showcase-wrapper {
    width: 90%;
  }

  .icon-options div {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1300px) {
  .icon-options div {
    font-size: 14px;
  }

  .showcase-wrapper .upper-part .types div {
    padding: 10px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  .showcase-wrapper .showcase-navbar ul li {
    font-size: 18px;
  }

  .wav-image {
    height: auto;
    max-height: 300px;
    width: 100%;
    margin: 0 auto;
  }

  .show-all {
    padding: 10px 32px 10px 33px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase-wrapper .upper-part .types {
    margin: 10px;
  }

  .showcase-wrapper .upper-part .types div {
    width: 70%;
  }

  .cards .row .col-md-4 {
    width: 100%;
    margin: 0 auto;
  }

  .showcase-wrapper .upper-part {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .showcase-wrapper .upper-part {
    margin-top: 32px;
  }

  .showcase-wrapper .upper-part .types div {
    width: 70%;
    font-size: 15px;
  }

  .showcase-wrapper h1 {
    font-size: 20px;
    width: 90%;
  }

  .wav-image {
    max-height: 100%;
  }

  .option-header span {
    font-size: 15px;
  }

  .showcase-wrapper .showcase-navbar ul li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .showcase-wrapper .upper-part {
    width: 100%;
    font-size: 15px;
  }

  .showcase-wrapper .showcase-navbar ul {
    overflow: auto;
    justify-content: space-between;
  }

  .showcase-wrapper .showcase-navbar ul li {
    white-space: nowrap;
    margin-right: 10px;
  }

  .showcase-wrapper .showcase-navbar ul li:last-child {
    margin-right: 0;
  }

  div.buyOrSale .buy-wrapper,
  div.buyOrSale .sell-wrapper,
  div.buyOrSale {
    height: auto;
  }

  .showcase-wrapper {
    padding: 44px 0px 0px;
  }

  .first-header img {
    max-width: 212px !important;
  }

  div.product-main-content h5 {
    font-size: 19px;
  }

}

@media (max-width:375px) {
  .wrapper .buttons {
    padding: 0px;
    width: 100%;
    margin-left: 0;
  }
}