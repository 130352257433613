img {
    max-width: 100%;
}

.auth_section_bg {
    height: 100vh;
    padding-top: 54px;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 1040;
}

.auth_section_bg_img {
    height: 100%;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.sidebar-open .main-nav {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    box-shadow: unset;
    background: transparent;
}

.auth_section_bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #004f6e69;
    backdrop-filter: blur(3px);
}

.sidebar_box::-webkit-scrollbar {
    display: none;
}

.sidebar_box {
    width: 470px;
    background-color: #F2F3F3;
    height: 100%;
    position: relative;
    margin-left: auto;
    padding: 50px 30px 30px;
    text-align: left;
    z-index: 1050;
    overflow-y: scroll;
}

.sidebar_box h1 {
    color: #004F6E;
    letter-spacing: 0.49px;
    font-size: 44px;
    font-family: Montserrat;
    font-weight: 300;
}

.sidebar_box p {
    color: #004F6E;
    font-size: 14px;
    font-family: Montserrat;
}

.sidebar_box p .comp_button {
    color: #00BADB;
    border: 0;
    background-color: transparent;
    padding: 0;
    display: inline-block;
    text-decoration-line: underline;
}

.comp_button {
    color: #00BADB;
    border: 0;
    background-color: transparent;
    padding: 0;
    display: inline-block;
    text-decoration-line: underline;
}

.auth_section_bg form .form-check label {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-size: 15px;
}

.auth_section_bg form .form-check input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 3px 0px 6px #00000029;
    border: 0 !important;
    opacity: 1;
    width: 20px;
    height: 20px;
    outline: unset !important;
}

.socila_login_btn .btn.social_btn1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #004F6E;
    border-radius: 46px;
    font-size: 18px;
    font-weight: 300;
    padding: 17px 15px 17px 5px;
    color: #004F6E;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
}

.socila_login_btn .btn.social_btn1 img {
    max-width: 24px;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.auth_section_bg form label {
    font-size: 16px;
    display: block;
    color: #747678;
    padding-left: 12px;
    opacity: 1;
}

.sidebar_box form.mt-4.pl-3 {
    padding: 0 !important;
}

.flex-fg input {
    width: 96% !important;
}

.flex-fg {
    display: flex;
    justify-content: space-between;
}

.auth_section_bg form input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border: 1px solid #DBDBDB;
    border-radius: 27px;
    opacity: 1;
    outline: none !important;
    width: 100%;
    font-size: 16px;
    padding: 12px 11px;
}

.auth_section_bg form div.position-relative .show_btn {
    letter-spacing: 0.42px;
    color: #747678;
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 50% !important;
    transform: translate(0, -50%);
}

.img_row_box.img_row_box1 span:first-child svg path {
    fill: #ffd401;
}

.img_row_box span:first-child svg {
    width: 15px !important;
}

.invalid-feedback-postn {
    position: absolute;
    margin-bottom: 30px;
}

.auth_section_bg .main_btn1 {
    background: #004F6E 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border: 1px solid #DBDBDB;
    opacity: 1;
    font-size: 20px;
    padding: 12px;
    width: 100%;
    border-radius: 100px;
}

.error_msg1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EA0026;
    opacity: 1;
    padding: 10px;
}

.error_msg1 h6 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
}

.error_msg1 p {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
    color: #000000;
}

.reset_section h1 {
    font-size: 29px;
    margin-bottom: 10px;
}

.check_mail_section h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.black_text p {
    color: #000000;
    font-size: 16px;
}

.check_mail_section h6 {
    font-size: 16px;
    font-weight: 600;
}

.back_link a {
    font-size: 22px;
    color: #00BADB;
    text-decoration-line: underline;
}

.back_link a svg {
    width: 21px;
    height: 21px;
}

@media (max-width:991px) {
    .sidebar_box {
        width: 450px;
    }
}

@media (max-width:767px) {
    .sidebar_box {
        width: 400px;
    }

    .socila_login_btn .btn.social_btn1 {
        padding: 17px 15px 17px 44px;
        font-size: 16px;
    }

    .sidebar_box h1 {
        font-size: 35px;
    }
}

@media (max-width:575px) {
    .sidebar_box {
        width: 100%;
        padding: 40px 21px 30px;
    }

    .socila_login_btn .btn.social_btn1 img {
        max-width: 20px;
        left: 23px;
    }

    .sidebar_box h1 {
        font-size: 28px;
    }

    .auth_section_bg form input {
        padding: 9px 11px;
    }

    .auth_section_bg .main_btn1 {
        font-size: 17px;
        padding: 9px;
    }
}