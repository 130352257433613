.search-result {
  margin: 9.5rem 0 0px;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
  font-family: "Montserrat", "Open Sans", sans-serif;
}

.search-nav {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 20vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}

.wrapper {
  display: flex;
  width: 90%;
  margin: 0 auto;
}

.search-nav .wrapper {
  min-height: 20vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}

.search-nav .wrapper .text-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}

.search-nav .wrapper .text-wrapper .subtitle {
  font-size: 15px;
  color: rgb(223, 223, 223);
}

.search-nav .logo {
  font-size: 25px;
  font-weight: bold;
}

.search-cards {
  border-bottom: 3px solid rgb(7, 204, 204);
  padding-bottom: 5rem;
}

.search-cards .card .card-top {
  padding: 0px;
}

/* .search-cards .card .card-top h5 {
  margin: 0 12px 20px 0;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: left;
  color: #00425d;
} */

.search-cards .card .card-top a {
  margin: 0 12px 20px 0;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.11px;
  text-align: left;
  color: #00425d;
}

.search-cards .card .card-top p {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.search-cards .card {
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
}

.search-cards .card .owner-img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

div.sidebar {
  width: 25%;
  position: relative;
  color: #fff;
}

div.sidebar .sidebar-header {
  background-color: rgb(7, 175, 197);
  margin: 20px auto 0px;
  text-align: center;
  padding: 30px 20px 20px;
  border-bottom: 3px solid #df4e24;
  text-transform: capitalize;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

div.sidebar .sidebar-header h1 {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

div.sidebar .sidebar-header p {
  margin: 5px 0 0;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: #fff;
}

div.sidebar .sidebar-main {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

div.sidebar ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

div.sidebar ul li:last-child {
  padding-bottom: 30px;
}

div.sidebar #filter-select {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00425d;
}

div.sidebar #filter-select:focus {
  background-color: #fff;
}

div.main-content {
  width: 75%;
  /* margin: 2rem; */
  margin-left: 2rem;
  text-align: start;
  margin-bottom: 5rem;
}

div.card-images img {
  /* width: 100px; */
  background-color: #f2f3f3;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

div.card-images img.citroen {
  background-color: #df4e24;
}

div.card .card-body {
  padding: 10px 25px;
  margin: 0;
}

div.card .card-body a {
  font-size: 1.2rem;
  color: #133440;
}

.card-footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.card-footer-wrapper .matches {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #616161;
}

.card-footer-wrapper .matches span {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 13px;
  font-weight: 600;
  color: #616161;
  margin-left: 2rem;
  border-bottom: dashed 1px #5f6161;
  cursor: pointer;
}

.card-footer-wrapper ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer-wrapper ul li {
  list-style: none;
  cursor: pointer;
  padding: 5px;
}

.card-footer-wrapper ul li.sort-by {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8e8b8b;
}

.card-footer-wrapper ul li svg {
  font-size: 27px;
}

.card-footer-wrapper ul li svg:last-child {
  background-color: #f2f3f3;
  padding: 7px;
}

.card-main-content .card {
  padding: 15px;
}

.card-body-carousel {
  display: flex;
  justify-content: space-between;
}

.card-body-carousel .left {
  width: 40%;
}

.card-body-carousel .left .carousel-img {
  /* height: 300px; */
  border-radius: 20px;
  height: auto !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.38);
  border: solid 1px #707070;
  background-color: transparent;
  max-height: 100% !important;
}

.card-body-carousel .right {
  width: 60%;

  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding: 0px 0px;
  margin: 0;
}

.card-body-carousel .right .rest {
  width: 100%;
}

.card-icon-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-icon-options div {
  text-transform: capitalize;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.48px;
  text-align: left;
  color: #000;
}

.card-icon-options div span {
  padding-right: 10px;
  font-size: 27px;
  color: #002e5b;
}

.card-list-details {
  width: 80%;
  margin: 0 0px 0 3rem;
}

.card-list-details ul {
  text-align: start;
}

.card-list-details ul li {
  list-style: none;
  text-align: start;
}

.card-list-details ul li {
  display: flex;
  justify-content: start;
  padding: 10px 0px 15px 0;
  margin-right: 3rem;
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
}

.card-list-details ul li div:last-child {
  margin-left: 10px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.54px;
  text-align: left;
  color: #747678;
}

.card-list-details ul li div:first-child {
  width: 35%;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.54px;
  text-align: left;
  color: #002e5b;
}

.card-contact-details ul {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.card-contact-details ul li {
  list-style: none;
  padding: 4.9px 0px 6.2px 0.1px;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.36px;
  text-align: left;
  color: #00425d;
}

.card-contact-details ul li:last-child {
  margin-left: 3rem;
}

.card-contact-details ul li svg:last-child {
  margin-left: 10px;
}

.card-contact-details ul li div {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #00859d;
}

.card-contact-details .share-this {
  padding: 5px 22px 5px 29px;
  border-radius: 23px;
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #5f6161;
  border: solid 1px #d4d1d1;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  margin-left: 3rem;
  margin-bottom: 1rem;
}

.card-contact-details .share-this:hover {
  color: #000;
}

.card-contact-details ul li.dots {
  border-bottom: 1px dotted #333;
}

.card-contact-details ul li button {
  font-size: 11px;
}

.card-contact-details ul li button svg {
  padding-right: 5px;
}

.quick-links .card {
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
}

.quick-links .card-title {
  color: #00425d;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00425d;
}

.quick-links .card-text span {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  border-right: 1px solid #000;
  padding: 2px 10px;
}

.quick-links .card-text span:last-child {
  border: none;
}

#result-filter {
  width: 80%;
}

#filter-search {
  flex-direction: column;
}

#filter-search li .search__container {
  border-bottom: 1px solid #ddd;
  padding-bottom: 2rem;
  width: 80%;
  margin: 0 auto;
}

#filter-search .search__input {
  width: 100%;
  padding: 12px 24px;
  transition: transform 250ms ease-in-out;
  font-size: 14px;
  line-height: 18px;
  color: #575756;
  background-color: #e9e9e9;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 2px;
  border: 1px solid transparent;
  border-right: 3px solid #002e5b;
  transition: all 250ms ease-in-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

#filter-search .search__input::placeholder {
  color: color(#575756 a(0.8));
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

#filter-search .search__input:hover,
#filter-search .search__input:focus {
  background-color: #fff;
  padding: 12px 0;
  outline: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #575756;
  border-radius: 0;
  background-position: 100% center;
}

#filter-search li .archive {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0 2rem;
  width: 80%;
  margin: 0 auto;
}

#filter-search li .archive h3 {
  padding: 20px 0 10px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e2e2e;
}

#filter-search li .filter-search-btn {
  background-color: #df4e24;
  border-color: #df4e24;
  border-radius: 3px;
  padding: 5px 10px;
  color: #fff;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-top: 20px;
}

.filter-clear-btn {
  padding: 6px 14px;
  font-size: 14px;
  border-radius: 0px;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.price-slider-values {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-slider-value {
  color: #002e5b;
  font-size: 15px;
  border: 1px solid #ddd;
  padding: 10px 10px;
  margin: 5px;
  width: 50%;
}


  input.price-slider-value:focus-visible {
    border: 0;
    outline: 1px solid  #ddd;

}
.card-body-carousel .left .carousel-img {
  height: 300px !important;
  max-height: 100% !important;
  object-fit: cover;
}
@media only screen and (max-width: 1961px) {
  .card-body-carousel .left {
    width: 36%;
  }



  .card-body-carousel .right {
    width: 64%;
  }

  .card-list-details ul li div:last-child {
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .card-list-details ul li div:first-child {
    width: 35%;
    font-size: 14px;
    letter-spacing: 0.4px;
  }
}

@media only screen and (max-width: 1500px) {
  .wrapper {
    width: 94%;
  }

  div.sidebar #filter-select {
    font-size: 14px;
  }

  .card-body-carousel .left {
    width: 38%;
  }

  .card-body-carousel .left .carousel-img {
    height: 280px;
    max-height: 100% !important;
  }

  .card-body-carousel .right {
    width: 62%;
  }

  .card-list-details ul li {
    padding: 7px 0px 10px 0;
    margin-right: 3rem;
  }

  .card-list-details ul li div:last-child {
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .card-list-details ul li div:first-child {
    width: 35%;
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .card-icon-options div {
    font-size: 14px;
    line-height: 1.2;
  }

  .card-icon-options div span {
    font-size: 23px;
  }

  .card-contact-details ul li {
    font-size: 15px;
    line-height: 1.5;
  }

  .card-contact-details ul li div {
    font-size: 16px;
  }

  .card-contact-details .share-this {
    padding: 5px 15px 5px 22px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 1250px) {
  .wrapper {
    width: 96%;
  }

  div.sidebar #filter-select {
    font-size: 12px;
  }

  .card-list-details ul li div:last-child {
    font-size: 12px;
    letter-spacing: 0.2px;
  }

  .card-list-details ul li div:first-child {
    width: 35%;
    font-size: 12px;
  }

  .card-icon-options div {
    font-size: 13px;
  }

  .card-icon-options div span {
    font-size: 21px;
  }

  .card-list-details {
    width: 80%;
    margin: 0px 0px 0px 1rem;
  }

  .card-contact-details ul li {
    font-size: 13px;
    line-height: 1.2;
  }

  .card-contact-details ul li:last-child {
    margin-left: 1rem;
  }

  .card-contact-details ul li div {
    font-size: 13px;
  }

  .card-contact-details .share-this {
    padding: 5px 15px 5px 22px;
    line-height: 1.1;
  }

  .card-contact-details ul li:last-child {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1100px) {
  .search-result .search-nav .wrapper {
    display: flex;
  }

  .search-result {
    /* margin: 6rem 0 0px; */
    padding: 0;
    background-color: #f2f3f3;
    min-height: 100vh;
  }

  .wrapper {
    display: inline-block;
  }

  div.sidebar {
    width: 96%;
    color: #fff;
    position: relative;
    margin: 0 auto;
  }

  div.sidebar ul {
    /* margin-left: 10%; */
    margin: 0 auto;
  }

  div.sidebar ul li {
    width: 50%;
    display: inline-block;
  }

  #filter-search {
    margin: 0 auto;
  }

  #filter-search li {
    display: block;
    width: 60%;
    margin: 0 auto;
  }

  div.sidebar ul li:last-child {
    margin-left: auto;
  }

  div.main-content {
    width: 96%;
    margin: 0 auto;
    text-align: start;
  }

  #result-filter {
    width: 90%;
  }
}
@media only screen and (max-width: 991px) {
  .price-slider-values{
    flex-wrap: wrap;
  }
  
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 96%;
    margin: 0 auto;
  }

  .card-body-carousel {
    display: inline-block;
  }

  .card-body-carousel .left {
    width: 100%;
    padding: 20px;
  }

  .card-body-carousel .right {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 0px;
    margin: 0 auto;
  }

  .card-list-details {
    width: 80%;
    margin: 0px;
  }

  .card-list-details ul {
    width: 100%;
  }

  .card-list-details ul li div:first-child,
  .card-list-details ul li div:last-child {
    width: 50%;
  }

  .card-contact-details ul {
    margin-top: 20px;
    display: block;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .card-contact-details ul li {
    margin-top: 5px;
  }

  .card-contact-details ul li:last-child {
    margin-left: 1rem;
  }

  .card-footer-wrapper {
    display: block;
  }

  .card-footer-wrapper ul {
    justify-content: start;
  }

  .card-footer-wrapper ul li:first-child {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .search-nav h1 {
    font-size: 20px;
    padding: 5px;
  }

  .sidebar-header h1 {
    font-size: 20px;
  }

  .sidebar-header p {
    font-size: 13px;
  }
}

/* @media only screen and (max-width: 530px) {
  div.sidebar {
    width: 100%;
    color: #fff;
    position: relative;
    margin: 0 auto;
  }
  div.sidebar .sidebar-header {
    margin: 20px 0px 10px;
    padding: 30px 5px 20px;
  }
  div.sidebar ul {
    margin: 0;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  div.sidebar ul li {
    width: 96%;
  }
  #filter-search li {
    width: 96%;
  }
  .card-footer-wrapper ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
} */
@media only screen and (max-width: 550px) {
  .card-main-content .card {
    margin-top: 25px !important;
  }

  #filter-search li {
    width: 100%;
  }

  .quick-links .card-title {
    font-size: 19px;
  }



  .d-flex.sort-by {
    flex-direction: column;
    justify-content: start;
    align-items: start !important;
  }

  .price-slider {
    width: 100% !important;
  }

  .d-flex.sort-by .css-5twyvt-MuiFormControl-root {
    margin: 6px 0 0 0;
  }

  .sidebar-main ul li:last-child {
    width: 100%;
    padding: 0 15px;
  }

  .search-result {
    margin: 8rem 0;
    padding: 0;
    background-color: #f2f3f3;
    min-height: 100vh;
  }

  .search-result .card-body-carousel .right {
    flex-direction: column;
  }

  .card-list-details {
    width: 100%;
    margin: 0 0 0 0;
  }

  .card-list-details ul li div:last-child {
    font-size: 12px;
    margin: 0;
  }

  .card-list-details ul li div:first-child {
    font-size: 12px;
  }

  .card-icon-options div {
    font-size: 12px;
  }

  .card-icon-options {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-icon-options div span {
    font-size: 19px;
  }

  .card-contact-details ul li {
    font-size: 12px;
    line-height: 1.1;
  }

  .card-contact-details ul li div {
    font-size: 13px;
  }

  .card-contact-details .share-this {
    margin: 0 auto 1rem;
  }
}

.card-images .img-fluid {
  margin-right: 17px;
  background-color: #ffffff;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
}

.citroen {
  background-color: #dc562c !important;
}

.card-images {
  display: flex;
}

.card-images .color {
  fill: #fff;
}



@media (max-width:1199px) {
  .card-body-carousel .left {
    width: 34%;
    padding: 20px 20px 20px 0;
  }

  .card-body-carousel .right {
    width: 67%;
  }
}

@media (max-width:768px) {
  .card-footer-wrapper ul {
    justify-content: space-between;
  }

  .card-body-carousel .right {
    width: 100%;
  }

  .card-body-carousel .left {
    width: 100%;
    padding: 20px 20px 20px 0;
  }

  .search-cards .card .owner-img {
    height: 45px;
    width: 45px;
  }

  /* .search-cards .card .card-top h5 {
    font-size: 18px;
  } */

  .search-cards .card .card-top a {
    font-size: 18px;
  }

  small {
    font-size: 14px !important;
  }

  .card-images .img-fluid {
    width: 43px;
    height: 38px;
  }

  div.card .card-body {
    padding: 10px 12px;
  }

  .card-footer-wrapper .matches {
    font-size: 14px;
    margin: 10px 0;
  }
}

@media (max-width:375px) {
  .card-footer-wrapper ul {
    flex-direction: column;
  }

  .card-footer-wrapper ul li.sort-by,
  .card-footer-wrapper ul li.sort-by div {
    width: 100%;
  }
}