#gallery-container {
  max-width: 900px;
  position: relative;
  text-align: center;
  overflow-x: hidden;
}
.gallery-img-container {
  transition: all 0.5s linear;
  // border: 2px solid #bbb;
  margin: 5px;
  vertical-align: top;
  position: relative;
  text-align: center;
}

.gallery-img {
  vertical-align: top;
  width: 800px;
  max-height: 430px;
  // margin: 30px 59px 38.7px 0;
  padding: 0;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.38);
  border: solid 1px #707070;
  /*   width : 550px; */
  max-width: 100%;
}

#slider-img-container {
  position: relative;
  /*   border-top : 2px solid #bbb; */
  max-width: 1270px;
  height: auto;
  left: 0px;
  white-space: nowrap;
  // overflow-x: hidden;
  transition: all 0.5s linear;
}

.slider-img {
  cursor: pointer;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  max-width: 160px;
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.38);
  border: solid 1px #707070;
  display: inline-block;
  vertical-align: top;
}

.slider-img.active {
  border: 2px solid #dc562c;
  box-shadow: 2px 2px 2px #888888;
  transform: scale(1.05);
}
#carousel-dot-container {
  position: absolute;
  bottom: 2%;
  width: 100%;

  z-index: 1;
}
.carousel-dot {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-left: 2%;
  color: #e6e6e6;
}
.carousel-dot:hover {
  transform: scale(1.5);
}
.carousel-dot.active {
  color: #dc562c;
}

.prev-carousel-button {
  cursor: pointer;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  padding-bottom: 0.15em;
  padding-left: 0.5em;
  padding-right: 0.8em;
  border-radius: 15px;
  color: #666666;
  position: absolute;
  left: 2%;
  top: 50%;
  height: 28px;
  user-select: none;
  box-shadow: 1px 1px 1px #888888;
}
.prev-carousel-button:hover {
  transform: scale(1.1);
}
.next-carousel-button {
  cursor: pointer;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  padding-bottom: 0.15em;
  padding-left: 0.8em;
  padding-right: 0.5em;
  border-radius: 15px;
  color: #666666;
  position: absolute;
  right: 2%;
  top: 50%;
  height: 28px;
  user-select: none;
  box-shadow: 1px 1px 1px #888888;
}
.next-carousel-button:hover {
  transform: scale(1.1);
}
.slideshow-button {
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  color: #f2f2f2;
  z-index: 2;
  font-size: 0%;
  position: absolute;
  font-size: 180%;
  padding: 0px;
  bottom: 2%;
  left: 2%;
}
.fullscreen-button {
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  color: #f2f2f2;
  padding: 0px;
  z-index: 2;
  font-size: 180%;
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.fullscreen-button:hover {
  transform: scale(1.1);
}
.slideshow-button:hover {
  transform: scale(1.1);
}
@media only screen and (max-width: 1600px) {
  #gallery-container {
    max-width: 800px;
  }
}

@media only screen and (max-width: 1500px) {
  #gallery-container {
    max-width: 700px;
  }
}
@media only screen and (max-width: 1400px) {
  #gallery-container {
    max-width: 600px;
  }
  .slider-img {
    cursor: pointer;

    max-width: 160px;
    height: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .slider-img {
    cursor: pointer;

    max-width: 110px;
    height: 70px;
  }
}
@media only screen and (max-width: 1150px) {
  #gallery-container {
    max-width: 500px;
  }
}
@media only screen and (max-width: 1050px) {
  #gallery-container {
    max-width: 450px;
  }
}
@media only screen and (max-width: 1000px) {
  #gallery-container {
    max-width: 600px;
  }
}
@media only screen and (max-width: 600px) {
  .slider-img {
    cursor: pointer;

    max-width: 100px;
    height: 60px;
  }
}
