.pagination-wrapper {
  display: flex;
  justify-content: center;
}
.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 2px 49.9px 2px;
  border-radius: 37.5px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #f9f9f9;
  width: auto;
  .pagination-item {
    margin: 0px 5px;
    padding: 12px 20px;
    box-sizing: border-box;
    min-width: 32px;
    font-family: "Open Sans", " Montserrat", sans-serif;

    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.54px;
    text-align: left;
    color: #4f4f50;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: #28b8cb;
      border-radius: 3px;
      cursor: pointer;
      color: #fff;
    }

    &.selected {
      background-color: #28b8cb;
      border-radius: 3px;
      color: #fff;
    }
  }
}
.pagination-chevron {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 22px 0;
  border-radius: 50%;
  padding: 8px 18px;
  max-height: 50px;
  cursor: pointer;
}
.pagination-chevron svg {
  font-family: "Open Sans", " Montserrat", sans-serif;

  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.54px;
  text-align: left;
  color: #4f4f50;
}
.pagination-disabled {
  pointer-events: none;
}
