#contact-us {
  margin: 8rem 0 2rem;
  padding: 0;
  background-color: #f2f3f3;
  /* min-height: 100vh; */
  font-family: "Montserrat", "Open Sans", sans-serif;
}

.contact-us-main {
  height: 30vh;
  width: 100%;
  background-image: url("/public/assets/newheader.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* #contact-us .contact-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 30vh;
  border-bottom: 3px solid rgb(233, 68, 3);
} */
#contact-us .contact-us-main .wrapper {
  min-height: 30vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}

#contact-us .contact-us-main .wrapper .text-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}

#contact-us .contact-us-main .wrapper .text-wrapper .subtitle {
  font-size: 15px;
  color: rgb(223, 223, 223);
}

#contact-us .contact-us-wrapper {
  background-color: #fff;
  width: 70%;
  position: relative;
  margin: 0 auto;
  /* top: 38vh;
  left: 50%; */
  /* transform: translate(-50%, 0); */
  padding: 3rem 4rem;
  text-align: start;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 3px #dc562c;
}

#contact-us .contact-us-wrapper input,
#contact-us .contact-us-wrapper textarea {
  background-color: #dddddd;
  border-color: #ccc;
  font-size: 13px;
  padding: 5px;
}

#contact-us .contact-us-wrapper input:focus,
#contact-us .contact-us-wrapper textarea:focus {
  background-color: #fff;
}

#contact-us .contact-us-wrapper h1 {
  margin-bottom: 3rem;
}

#contact-us .contact-us-wrapper div.col-sm-12 input {
  padding: 5px 5px;
}

#contact-us .contact-us-wrapper div.col-sm-12:last-child div:last-child {
  padding: 0;
}

#contact-us .contact-us-wrapper div.col-sm-12:last-child button {
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  border-color: #dd572c;
  margin-top: 25px;
  padding: 16px 35px 16px 43px;
  border-radius: 31px;
  background-color: #dc562c;
}

#contact-us .contact-us-wrapper div.col-sm-12 #message {
  height: 116px;
}

#contact-us .contact-us-wrapper .form-check {
  margin-top: -2.6rem;
}

#contact-us .contact-us-wrapper .form-check label {
  font-size: 12px;
  color: rgb(141, 141, 141);
}

#contact-us .color-cards {
  margin-top: 30vh;
}

#contact-us .color-cards .red-card {
  background-color: #dd572c;
  width: 72%;
  margin: 2rem auto;
  padding: 2rem 1rem;
  color: #fff;
  text-align: start;
  font-size: 1.3rem;
}

#contact-us .color-cards .blue-card {
  background-color: #004f6e;
  width: 72%;
  margin: 2rem auto;
  padding: 2rem 1rem;
  color: #fff;
  text-align: start;
  font-size: 1.3rem;
}

#contact-us .color-cards .red-card a,
#contact-us .color-cards .blue-card a {
  color: #fff;
  text-decoration: underline;
}

#contact-us .contact-panel-nav {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #232628;
  color: #fff;
  font-size: 14px;
  width: 70%;
  margin: 0 auto;
}

#contact-us .contact-panel-nav div {
  margin-right: 20px;
}

.contact-panel-selected {
  background-color: #fff;
  color: #232628;
  height: 100%;
  padding: 12px;
  border-top: 2px solid #dd572c;
}

#contact-us .contact-nav-panels-wrapper {
  width: 70%;
  margin: 0 auto;
  background-color: #fff;
  padding: 2rem;
}

#contact-us .contact-nav-panels-wrapper .phone,
#contact-us .contact-nav-panels-wrapper .email {
  display: flex;
  align-items: center;
  padding: 1rem;
}

#contact-us .contact-nav-panels-wrapper .phone .icon,
#contact-us .contact-nav-panels-wrapper .email .icon {
  color: #dd572c;
  font-size: 30px;
}

#contact-us .contact-nav-panels-wrapper div.phone .icon {
  border: 1px solid #dd572c;
  border-radius: 50%;
  padding: 5px 7px;
  font-size: 15px;
}

#contact-us .contact-nav-panels-wrapper div.phone div.text div,
#contact-us .contact-nav-panels-wrapper div.email div.text div {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: start;
  margin-left: 3rem;
}

#contact-us .contact-nav-panels-wrapper .phone div.text a,
#contact-us .contact-nav-panels-wrapper .email div.text a {
  font-size: 1.2rem;
  margin-left: 3rem;
  text-decoration: none;
  color: #232628;
}

#contact-us .opening-times-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  text-align: start;
}

#contact-us .opening-times-wrapper .icon svg {
  font-size: 2rem;
  color: #dd572c;
  margin-bottom: 5rem;
}

#contact-us .opening-times-wrapper div.text {
  width: 100%;
  padding: 2rem 2rem 2rem 1rem;
}

#contact-us .opening-times-wrapper div.text h2 {
  margin-left: 1.4rem;
}

#contact-us .opening-times-wrapper .day-and-hour {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: start;
  margin-left: 1.4rem;
  border-top: #ccc solid 1px;
  padding: 3px;
}

#contact-us .opening-times-wrapper .day-and-hour:last-child {
  border-bottom: #ccc solid 1px;
}

#contact-us .opening-times-wrapper .day-and-hour div.day {
  /* margin-right: 15rem; */
}

#contact-us .social-media-wrapper {
  display: flex;
}

#contact-us .social-media-wrapper .icon svg {
  font-size: 40px;
  color: #fff;
  background-color: #232628;
  padding: 10px;
  margin: 5px;
}

@media only screen and (max-width: 1200px) {
  margin: 6rem 0 0;
}

@media only screen and (max-width: 1000px) {
  #contact-us .contact-us-wrapper {
    background-color: #fff;
    width: 80%;
    padding: 2rem;
  }

  #contact-us .contact-us-wrapper .form-check {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  #contact-us .contact-us-wrapper {
    background-color: #fff;
    width: 90%;
  }

  #contact-us .contact-us-wrapper .form-check {
    margin-top: 1rem;
  }

  #contact-us .color-cards {
    margin-top: 47vh;
  }

  #contact-us .color-cards .red-card {
    width: 100%;
    font-size: 1.1rem;
  }

  #contact-us .color-cards .blue-card {
    width: 100%;
    font-size: 1.1rem;
  }

  #contact-us .contact-panel-nav {
    width: 100%;
  }

  #contact-us .contact-nav-panels-wrapper {
    width: 100%;
  }

  #contact-us .opening-times-wrapper .icon {
    display: none;
  }

  #contact-us .opening-times-wrapper div.text {
    width: 100%;
    padding: 0px;
  }

  #contact-us .opening-times-wrapper div.text h2 {
    margin-left: 0px;
  }

  #contact-us .opening-times-wrapper .day-and-hour {
    margin-left: 0px;
  }
}

@media (max-width:1250px) {
  #contact-us .contact-us-main .wrapper {
    display: flex;
  }
}

@media (max-width:575px) {
  #contact-us .contact-us-wrapper div.col-sm-12:last-child button {
    padding: 9px 35px 9px 43px;
  }
}