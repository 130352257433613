div.buyOrSale {
  /* background-image: url("/public/assets/newheader.png"); */
  background-image: url("/public/assets/wheelchairpattern.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  color: #fff;
  margin-top: 3rem;
}

div.buyOrSale p {
  font-family: "Open Sans", "Montserrat", sans-serif;
  color: #fff;
}
div.buyOrSale .buy-wrapper {
  height: 100%;
  width: 50%;
  background-color: rgba(16, 168, 202, 0.83);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
div.buyOrSale .sell-wrapper {
  height: 100%;
  width: 50%;
  padding: 20px;
  background-color: rgba(12, 82, 114, 0.83);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buy-or-sale-button {
  border-radius: 34px;
  padding: 10px 20px;
  background-color: #dc562c;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.4px;
  text-align: left;
  color: #fff;
}
div.buyOrSale h1 {
  font-size: 40px;
  font-weight: bold;
  font-family: "Montserrat", "Open Sans", sans-serif;
}
div.buyOrSale .icon svg {
  font-size: 110px;
}
div.buyOrSale .icon img {
  max-height: 100px;
}
@media only screen and (max-width: 1000px) {
  .buy-or-sale-button {
    font-size: 17px;
    padding: 8px 10px;
  }
  div.buyOrSale h1 {
    font-size: 23px;
  }
  div.buyOrSale .icon svg {
    font-size: 100px;
  }
  .buy-or-sale-button {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 768px) {
  div.buyOrSale {
    display: block;
  }
  div.buyOrSale .buy-wrapper {
    height: 50%;
    width: 100%;
    padding-top: 3rem;
  }
  div.buyOrSale .sell-wrapper {
    width: 100%;
    height: 50%;
    padding-top: 3rem;
  }
  div.buyOrSale .icon svg {
    font-size: 70px;
  }
  div.buyOrSale .icon img {
    height: 80px;
  }
  div.buyOrSale h1 {
    font-size: 18px;
  }
  div.buyOrSale p {
    font-size: 14px;
  }
  div.buyOrSale button {
    font-size: 12px;
    margin-bottom: 2rem;
  }
  .buy-or-sale-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}
