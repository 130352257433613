#hire {
  min-height: 100vh;
  margin: 8rem 0 0px;
  padding: 0;
  background-color: #f2f3f3;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
}

#hire .hire-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 30vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}
#hire .hire-header .wrapper {
  min-height: 30vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}
#hire .hire-header .wrapper .text-wrapper {
  width: 80%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}
#hire .hire-header .wrapper .text-wrapper .subtitle {
  font-size: 15px;
  color: rgb(223, 223, 223);
}

/* HIRE CARDS  */
#hire .hire-cards .hire-car-cards,
.hire-date-cards,
#hire .hire-date-and-passcode,
.hire-details {
  width: 80%;
  margin: 3rem auto;
  border: rgb(233, 68, 3) solid 2px;
  padding: 2.5rem 5rem;
  background-color: #fff;
}
#hire .hire-cards .hire-car-cards .hire-card{
  border: 3px solid #fff;
}
#hire .hire-cards .hire-car-cards .hire-card,
#hire .hire-cards .hire-date-cards .hire-card {
  margin-top: 20px;
}
#hire .hire-cards .hire-car-cards .hire-card:hover,
#hire .hire-cards .hire-date-cards .hire-card .hire-card-content:hover {
  border: 3px solid rgb(233, 68, 3);
  border-radius: 23px;
}
.hire-cards .hire-car-cards .hire-card-selected {
  border: 3px solid rgb(233, 68, 3);
  border-radius: 20px;
}
#hire .hire-cards .hire-car-cards .hire-card .hire-card-header {
  background-color: #002e5b;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 5px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  position: relative;
}
#hire .hire-cards .hire-car-cards .hire-card .hire-card-header span {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgb(233, 68, 3);
  border-top-left-radius: 20px;
  padding: 5px 10px;
}

#hire .hire-cards .hire-car-cards .hire-card .hire-card-footer {
  color: rgb(54, 53, 53);
  text-align: center;
     margin-bottom: 13px;
}

#hire .hire-cards .hire-date-cards .hire-card .hire-card-content {
  background-color: #002e5b;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
  position: relative;
  height: 150px;
  width: 100%;
  font-size: 41px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
#hire .hire-cards .hire-date-cards .hire-card .hire-card-content span {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgb(233, 68, 3);
  border-top-left-radius: 20px;
  padding: 5px 10px;
  font-size: 20px;
}

#hire .hire-cards .hire-car-cards .hire-card .hire-card-footer {
  color: rgb(54, 53, 53);
}

/* DATE  */

#hire .hire-date-and-passcode .date .mobile {
  display: none;
}
#hire .hire-date-and-passcode .date-and-passcode-wrapper {
  border-bottom: 2px solid #153e4d;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 3rem;
}
#hire .hire-date-and-passcode .date-and-passcode-wrapper h3 {
  margin: 1rem auto;
  font-size: 25px;
  font-weight: bold;
  color: #002e5b;
  text-align: center;
}
#hire .hire-date-and-passcode .date-and-passcode-wrapper .checkbox {
  text-align: end;
  font-size: 13px;
}
#hire .hire-date-and-passcode .date-and-passcode-wrapper .checkbox input {
  margin-right: 10px;
}

/* HIRE DETAILS  */
#hire .hire-details {
  margin-bottom: 3rem;
}
#hire .hire-details h3 {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #002e5b;
}
#hire .hire-details .form-group label {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 12px;
}
#hire .hire-details form label {
  display: flex;
}
#hire .hire-details .video {
  border: 1px solid #153e4d;
  width: 100%;
  height: 84%;
  margin-top: 10px;
}
#hire .hire-details .video-title {
  text-align: start;
}
#hire .hire-details button {
  border-color: rgb(233, 68, 3);
  color: #fff;
  margin-top: 20px;
  padding: 20px 110px 20px 109px;
  border-radius: 50px;
  background-color: #dc562c;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 1400px) {
  #hire .hire-cards .hire-date-cards .hire-card .hire-card-content {
    font-size: 31px;
  }
  #hire .hire-details button {
    padding: 15px 70px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 1000px) {
  #hire {
    margin: 6rem 0 0px;
  }

  #hire .hire-cards .hire-date-cards .hire-card .hire-card-content {
    font-size: 25px;
  }
  #hire .hire-header .wrapper .text-wrapper {
    width: 90%;
    margin: 4rem auto 0;
    align-items: center;
    justify-content: center;
  }
  #hire .hire-date-and-passcode .date-and-passcode-wrapper h3 {
    margin: 1rem auto;
    font-size: 20px;
  }
  #hire .hire-details h3 {
    font-size: 20px;
  }
  #hire .hire-details button {
    padding: 12px 50px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  #hire .hire-header .wrapper {
    /* margin-top: 3rem; */
  }
  #hire .hire-cards .hire-car-cards,
  .hire-date-cards,
  #hire .hire-date-and-passcode,
  .hire-details {
    width: 90%;
    padding: 1.5rem 2rem;
  }
  #hire .hire-date-and-passcode .date-and-passcode-wrapper {
    width: 90%;
  }
  #hire .hire-date-and-passcode .date .mobile {
    display: block;
  }
  #hire .hire-date-and-passcode .date .desktop {
    display: none;
  }
  #hire h3 {
    font-size: 25px;
  }
  #hire button {
    font-size: 14px;
    padding: 10px 20px;
    margin-top: 20px;
  }
  #hire .hire-details .video-div {
    margin-bottom: 3rem;
  }
  #hire .hire-details .video {
    width: 100%;
    min-height: 100px;
    margin-bottom: 3rem;
  }
  #hire .hire-details button {
    padding: 12px 20px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  #hire .hire-cards .hire-car-cards,
  .hire-date-cards,
  #hire .hire-date-and-passcode {
    width: 96%;
    padding: 1.5rem 2rem;
  }
  #hire h3 {
    font-size: 20px;
  }
}
