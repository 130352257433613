#minibus {
  min-height: 100vh;
  margin: 8rem 0 0px;
  padding: 0;
  background-color: #f2f3f3;
}

#minibus .minibus-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 30vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}

#minibus .minibus-header .wrapper {
  min-height: 30vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}

#minibus .minibus-header .wrapper .text-wrapper {
  width: 70%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #ccc;
  font-weight: bold;
}

#minibus .minibus-header .wrapper .text-wrapper h1 {
  font-weight: bold;
  font-size: 30px;
}

#minibus .minibus-header .wrapper .text-wrapper .options {
  display: flex;
  font-size: 12px;
  color: #153e4d;
  font-weight: 400;
  height: auto;
}

#minibus .minibus-header .wrapper .text-wrapper .options .option {
  margin: 0px 2rem 0px 0px;
  color: #fff;
}

#minibus .minibus-main-content {
  display: block;
  width: 70%;
  margin: 2rem auto;
  text-align: start;
}

#minibus .minibus-main-content .minibus-sidebar {
  color: #1b262b;
}

#minibus .minibus-main-content .minibus-sidebar .used-wavs img {
  width: 100%;
  margin: 1.5rem 0px;
}

#minibus .minibus-main-content .minibus-sidebar .new-wavs {
  margin-top: 1rem;
}

#minibus .minibus-main-content .minibus-sidebar .recent-posts {
  margin-top: 2rem;
  border-bottom: 1px solid #aaa;
  padding-bottom: 3rem;
}

#minibus .minibus-main-content .minibus-sidebar .recent-posts ul {
  width: 90%;
  margin: 0 auto;
}

#minibus .minibus-main-content .minibus-sidebar ul li {
  margin-top: 5px;
}

#minibus .minibus-main-content .minibus-sidebar ul li a {
  color: #11323e;
  font-size: 14px;
}

#minibus .minibus-main-content .minibus-sidebar ul li a:hover {
  color: rgb(14, 144, 177);
}

#minibus .minibus-main-content .minibus-sidebar .top-makes .icons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-makes h2,
.used-wavs h2,
.new-wavs h2,
.recent-posts h2 {
  font-size: 24px;
}

#minibus .minibus-main-content .minibus-sidebar .top-makes .icons .icon {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 12px;
  /* border: 1px solid #fff; */
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0 0;
  cursor: pointer;
  width: 48%;
}

#minibus .minibus-main-content .minibus-sidebar .top-makes .icons .icon div {
  /* margin-top: 10px; */
  padding: 0;
  text-align: center;
}

#minibus .minibus-main-content .minibus-sidebar .top-makes .icon img {
  max-width: 100%;
}

#minibus .minibus-main-content .minibus-main p {
  font-size: 16px;
}

#minibus .minibus-main-content .minibus-main .select-image img {
  height: 200px;
  width: 100%;
}

#minibus .minibus-main-content .minibus-main .why-opt-for-minibus img {
  border-bottom: 3px solid #1b262b;
  padding-bottom: 3rem;
}

#minibus .minibus-main-content .minibus-main .select-image {
  font-size: 24px;
  margin-bottom: 3rem;
}

#minibus .minibus-main-content .minibus-main .select-image div:first-child {
  margin-bottom: 3rem;
}

#minibus .minibus-main-content .minibus-main .why-opt-for-minibus h2 {
  font-size: 24px;
  margin-bottom: 2rem;
}

#minibus .minibus-main-content .minibus-main .why-opt-for-minibus ul {
  width: 96%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

#minibus .minibus-main-content .minibus-main .loading {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 2rem 0px 3rem;
}

#minibus .minibus-main-content .minibus-main .loading .icon svg {
  font-size: 30px;
  color: rgb(223, 11, 11);
  margin-right: 3rem;
}

#minibus .minibus-main-content .minibus-main .loading .text {
  font-size: 18px;
}

#minibus .minibus-main-content .minibus-main .why-opt-for-wav h2 {
  font-size: 24px;
  margin-bottom: 2rem;
}

#minibus .minibus-main-content .minibus-main .why-opt-for-wav img {
  margin: 2rem 0px;
}

#minibus .minibus-main-content .minibus-main .mercedes-benz h2 {
  font-size: 24px;
  margin-bottom: 2rem;
}

#minibus .minibus-main-content .minibus-main .mercedes-benz .upper {
  width: 70%;
  margin: 2rem auto;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .button {
  margin: 2rem auto;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .button button {
  width: 100%;
  padding: 10px;
  background-color: #dd572c;
  border-color: #dd572c;
  color: #fff;
  border-radius: 10px;
  font-size: 1.3rem;
}

#minibus .minibus-main-content .minibus-main .minibus-footer div a {
  color: #11323e;
  font-size: 16px;
  font-weight: bold;
}

#minibus .minibus-main-content .minibus-main .minibus-footer div a:hover {
  color: rgb(14, 144, 177);
}

#minibus .minibus-main-content .minibus-main .minibus-footer .category-and-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0px 2rem;
  padding-bottom: 3rem;
  border-bottom: 4px solid #1b262b;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .category-and-share .category {
  font-size: 14px;
  font-weight: bold;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .category-and-share .share button {
  font-size: 12px;
  color: #888;
  padding: 5px 15px;
  border-color: #888;
  text-transform: uppercase;
  border-radius: 20px;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .category-and-share .share button svg {
  margin: 0px 5px;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .author {
  display: flex;
  align-items: center;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .author .icon svg {
  font-size: 80px;
  background-color: #153e4d;
  padding: 10px 20px;
  color: #fff;
  border-radius: 50%;
  margin-right: 3rem;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .author .text div:first-child {
  font-size: 12px;
}

#minibus .minibus-main-content .minibus-main .minibus-footer .author .text div:last-child {
  font-size: 12px;
}

@media only screen and (max-width: 1000px) {
  #minibus {
    margin: 6rem 0 0px;
  }

  #minibus .minibus-header .wrapper .text-wrapper {
    margin-top: 3rem;
  }

  #minibus .minibus-main-content {
    width: 96%;
  }

  #minibus .minibus-main-content .minibus-sidebar {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  #minibus .minibus-header .wrapper .text-wrapper {
    width: 98%;
    margin: 5rem auto;
  }

  #minibus .minibus-main-content .minibus-main .select-image img {
    height: 100%;
  }

  #minibus .minibus-main-content .minibus-main .loading .text {
    font-size: 15px;
  }

  #minibus .minibus-main-content .minibus-main .select-image {
    font-size: 19px;
  }

  #minibus .minibus-main-content .minibus-main p {
    font-size: 14px;
  }

  #minibus .minibus-header .wrapper .text-wrapper h1 {
    font-size: 25px;
  }

  #minibus .minibus-header .wrapper .text-wrapper .options .option {
    margin: 0px 1rem 0px 0px;
    color: #fff;
  }
}

@media (max-width:1100px) {
  #minibus .minibus-header .wrapper {
    display: flex;
  }
}