%transition {
  transition: 0.12s;
}
.card-buttons {
  padding: 5px;
  width: 100%;
  margin: 5px auto 0;
}
.card-buttons a {
  text-decoration: none;
}
.card-buttons a span {
  padding: 7px 10px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.56px;
  color: #fff;
}
.card-buttons .price-btn {
  @extend %transition;
  display: inline-block;
  padding: 10px 20px 10px 0px;
  text-decoration: none;
  color: #fff;
  position: relative;
  width: 35%;
  margin-right: 5px;
  text-align: start;
  > span {
    position: relative;
    z-index: 5;
    text-align: left;
  }

  &:after {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #28b8cb;
    border-width: 3px 3px 3px 0;
    transform: skewX(-25deg);
    background-color: #28b8cb;
  }

  &:before {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 20px;
    border: solid 1px #28b8cb;
    border-width: 3px 0 3px 3px;
    background-color: #28b8cb;

    z-index: 8;
  }
}

.price-btn:hover,
.price-btn:focus {
  color: #fff;

  &:after {
    background: #1dbcd1;
  }

  &:before {
    background: #1dbcd1;
  }
}

.card-buttons .enquire-btn {
  @extend %transition;
  display: inline-block;
  padding: 10px 5px 10px 15px;
  text-decoration: none;
  color: #fff;
  position: relative;
  text-align: end;
  width: 55%;
  margin-left: 20px;
  > span {
    position: relative;
    text-align: end;
    z-index: 5;
  }

  &:after {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #dc562c;
    border-width: 3px 3px 3px 0;
    background-color: #dc562c;
  }

  &:before {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    bottom: 0;
    width: 40px;
    border: solid 1px #dc562c;
    border-width: 3px 0 3px 3px;
    background-color: #dc562c;
    z-index: 8;
    transform: skewX(-25deg);
  }
}

.enquire-btn:hover,
.enquire-btn:focus {
  color: #fff;

  &:after {
    background: #e45023;
  }

  &:before {
    background: #e45023;
  }
}

@media only screen and (max-width: 1650px) {
  .card-buttons a span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.2;
  }
  .card-buttons .price-btn {
    padding: 8px 20px 8px 0px;
    width: 40%;
  }

  .card-buttons .enquire-btn {
    padding: 8px 5px 8px 5px;
    width: 51%;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .card-buttons {
    padding: 0px;
  }

  .card-buttons a span {
    padding: 5px 0px 0 15px;
    font-size: 13px;
    line-height: 1;
  }

  .card-buttons .price-btn {
    padding: 6px 10px 6px 0px;
    width: 35%;
    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .card-buttons .enquire-btn {
    width: 55%;
    margin-left: 10px;
    padding: 6px 10px 6px 0px;
    &:before {
      left: -8px;
      width: 20px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .card-buttons {
    padding: 0px;
  }

  .card-buttons a span {
    padding: 5px 8px;
    font-size: 13px;
    line-height: 1;
    text-align: center;
  }
  .card-buttons .price-btn {
    padding: 6px 10px 6px 10px;

    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .card-buttons .enquire-btn {
    padding: 6px 0px 6px 0px;
    &:before {
      left: -12px;
      width: 25px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .card-buttons a span {
    padding: 5px 5px;
    font-size: 12px;
  }
  .card-buttons .price-btn {
    padding: 6px 10px 6px 10px;
  }

  .card-buttons .enquire-btn {
    padding: 6px 0px 6px 0px;
  }
}

@media only screen and (max-width: 550px) {
  .card-buttons a span {
    padding: 5px 5px;
    font-size: 12px;
  }
  .card-buttons .price-btn {
    padding: 6px 0px 6px 5px;
    width: 35%;
    margin-left: 2rem;
    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .card-buttons .enquire-btn {
    padding: 6px 0px 6px 5px;
    width: 50%;
    &:before {
      left: -12px;
      width: 25px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .card-buttons a span {
    font-size: 11px;
  }
  .card-buttons .price-btn {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: center;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);
    margin-left: 0;
    > span {
      text-align: center;
    }

    &:after {
      left: 0;
      border: solid 1px #28b8cb;
      border-width: 0px 0px 0px 0;
      transform: skewX(0deg);
    }

    &:before {
      left: 0px;
      bottom: 0;
      width: 0px;
      border-width: 0px 0 0px 0px;
    }
  }

  .card-buttons .enquire-btn {
    margin-right: 0px;
    text-align: center;
    width: 100%;
    margin-left: 0px;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);
    > span {
      position: relative;
      text-align: end;
      z-index: 5;
    }

    &:after {
      border-width: 0;
    }

    &:before {
      left: 0px;
      width: 0px;
      border-width: 0px;
      transform: skewX(0deg);
    }
  }
}

@media only screen and (max-width: 320px) {
  .card-buttons a span {
    font-size: 11px;
  }
  .card-buttons .price-btn {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: center;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);

    > span {
      text-align: center;
    }

    &:after {
      left: 0;
      border: solid 1px #28b8cb;
      border-width: 0px 0px 0px 0;
      transform: skewX(0deg);
    }

    &:before {
      left: 0px;
      bottom: 0;
      width: 0px;
      border-width: 0px 0 0px 0px;
    }
  }

  .card-buttons .enquire-btn {
    margin-right: 0px;
    text-align: center;
    width: 100%;
    margin-left: 0px;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);
    > span {
      position: relative;
      text-align: end;
      z-index: 5;
    }

    &:after {
      border-width: 0;
    }

    &:before {
      left: 0px;
      width: 0px;
      border-width: 0px;
      transform: skewX(0deg);
    }
  }
}
