.form_heading_styling {
    font-size: 45px;
    font-weight: bold;
    font-family: 'Montserrat';
    text-align: left;
   color: #004F64;
 
}

.form_heading_styling .mgnifier-icon {
    filter: grayscale(0) invert(1);
    width: 50px;
}
span.first_heading_form {
    color: white;
}
span.second_heading_form {
    color: #004f63;
}
.main_heading_form p {
    margin-bottom: 0px !important;
    text-align: left;
}
.main_heading_form {
    background-color: #28b8cb;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

       
}
span.decoration_styling {
    text-decoration: underline;
}
select.form-select {
    height: 44px;
    width: 100%;
    border-radius: 5px;
}
.enquiry_form_styling2 {
   padding: 20px;
    border-radius: 10px;
    border-top: 2px solid #004f63;
    background: #fff;
}
.submit_btn_styling input.form-control {
    width: 200px;
    background: #dc562c;
    color: #fff;
    font-weight: 700;
    border-radius: 27px;
}
.enquiry_form_styling {
    border: 2px solid #004f63;
    border-radius: 10px;
    background: #28b8cb;
}

.customSelect .form-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
}

.customSelect::after {
    content: '';
    position: absolute;
    background-color: transparent;
    border-bottom: 2px solid #181818;
    border-left: 2px solid #181818;
    height: 10px;
    width: 10px;
    top: 15px;
    transform: rotate(-45deg);
    right: 15px;
    border-radius: 1px;
    pointer-events: none;
}