%transition {
  transition: 0.12s;
}
.grid-buttons {
  // margin-top: 5px;
  padding: 5px;
  width: 100%;
  margin: 5px auto 0;
  margin-left: 10px;
}
.grid-buttons a {
  text-decoration: none;
}
.grid-buttons a span {
  padding: 7px 20px;
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.56px;
  color: #fff;
}
.grid-buttons .price-btn {
  @extend %transition;
  display: inline-block;
  padding: 10px 20px 10px 0px;
  text-decoration: none;
  position: relative;
  width: 35%;
  margin-right: 5px;
  text-align: start;
  > span {
    position: relative;
    z-index: 5;
    text-align: left;
  }

  &:after {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #28b8cb;
    border-width: 3px 3px 3px 0;
    transform: skewX(-25deg);
    background-color: #28b8cb;
  }

  &:before {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    bottom: 0;
    width: 28px;
    border: solid 1px #28b8cb;
    border-width: 3px 0 3px 3px;
    background-color: #28b8cb;

    z-index: 8;
  }
}

.grid-buttons .enquire-btn {
  @extend %transition;
  display: inline-block;
  padding: 10px 5px 10px 15px;
  text-decoration: none;
  color: #fff;
  position: relative;
  text-align: end;
  width: 55%;
  margin-left: 20px;
  > span {
    position: relative;
    text-align: end;
    z-index: 5;
  }

  &:after {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 1px #dc562c;
    border-width: 3px 3px 3px 0;
    background-color: #dc562c;
  }

  &:before {
    @extend %transition;
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    bottom: 0;
    width: 30px;
    border: solid 1px #dc562c;
    border-width: 3px 0 3px 3px;
    background-color: #dc562c;
    z-index: 8;
    transform: skewX(-25deg);
  }
}

@media only screen and (max-width: 1750px) {
  .grid-buttons a span {
    padding: 5px 15px;
    font-size: 12px;
    line-height: 1.2;
  }
  .grid-buttons .price-btn {
    padding: 8px 20px 8px 0px;
    width: 35%;
  }

  .grid-buttons .enquire-btn {
    padding: 6px 5px 6px 5px;
    width: 55%;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .grid-buttons {
    padding: 0px;
    width: 100%;
  }
  .grid-buttons a {
    margin-left: 5px;
  }
  .grid-buttons a span {
    padding: 5px 12px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
  }
  .grid-buttons .price-btn {
    padding: 8px 20px 8px 0px;
    width: 30%;
    margin-left: 10px;
    &:after {
      border-width: 3px 3px 3px 0;
      transform: skewX(-15deg);
    }

    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .grid-buttons .enquire-btn {
    padding: 8px 2px 8px 2px;
    width: 50%;
    &::before {
      border-width: 3px 3px 3px 0;
      transform: skewX(-15deg);
      left: -8px;
      width: 25px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .grid-buttons {
    padding: 0px;
    width: 100%;
  }

  .grid-buttons a span {
    padding: 5px 8px;
    font-size: 11px;
    line-height: 1;
    text-align: center;
  }
  .grid-buttons .price-btn {
    padding: 6px 20px 6px 0px;

    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .grid-buttons .enquire-btn {
    padding: 6px 0px 6px 0px;
    &:before {
      left: -8px;
      width: 25px;
    }
  }
}
@media only screen and (max-width: 500px) {
  .grid-buttons a span {
    padding: 5px 5px;
    font-size: 12px;
  }
  .grid-buttons .price-btn {
    padding: 6px 10px 6px 5px;

    &:before {
      left: -10px;
      width: 20px;
    }
  }

  .grid-buttons .enquire-btn {
    padding: 6px 0px 6px 0px;
    &:before {
      left: -12px;
      width: 25px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .grid-buttons a span {
    padding: 5px 5px;
    font-size: 12px;
  }
  .grid-buttons .price-btn {
    width: 90%;
    margin-right: 0px;
    margin-bottom: 10px;
    margin: 0 auto 10px;
    text-align: center;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);

    > &:after {
      width: 0px;
      left: 0;
      border-width: 0px;
      background-color: transparent;
      border: none;
      transform: skewX(1deg);
    }

    &:before {
      left: 0px;
      transform: skewX(0deg);
      bottom: 0;
      border-width: 0px;
      width: 0px;
    }
  }

  .grid-buttons .enquire-btn {
    margin-right: 0px;
    text-align: center;
    width: 90%;
    margin-left: 0px;
    box-shadow: 2px 2px 4px 0 rgba(7, 7, 7, 0.49);
    > &:after {
      border-width: 0;
    }

    &:before {
      left: 0px;
      width: 0px;
      border-width: 0px;
      transform: skewX(0deg);
    }
  }
}
