#main {
  background-color: rgba(3, 71, 103, 0.6);
}

.search-wrapper {
  // position: absolute;
  // top: 34%;
  // left: 20%;
  color: white;
  padding: 15% 0 10%;
  margin: 0 auto;
  width: 70%;
  font-family: "Montserrat", "Open Sans", sans-serif;
}

.title {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 100px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -4.4px;
  text-align: left;
  color: #dc562c;
}

.subtitle {
  font-family: "Montserrat", "Open Sans", sans-serif;

  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: left;
  color: #fff;
  margin: 20px 0px 40px 9px;
}

.search-component .upper-part {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  margin-top: 20px;
  //   border: 1px solid #ccc;
  background-color: #00425d;
}

.search-component .upper-part .types div {
  border: 1px solid #ccc;
  padding: 13px;
  width: 30%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  // text-align: left;
  color: #fff;
  background-color: #28b8cb;
}

.search-component .upper-part .types div.passengers {
  font-family: "Roboto", sans-serif;
  border-top-left-radius: 5px;
}

.search-component .upper-part .types div.drivers {
  border-top-right-radius: 5px;
}

.on-focus {
  background-color: #df4e24 !important;
}

.search-component .lower-part {
  height: auto;
  width: 100%;
  margin: 0px 26px 0 0;
  padding: 20px 54px 40px 56.5px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.53);
  background-color: #fff;
}

.search-component .lower-part .main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.search-component .lower-part .keywords {
  margin-top: 1rem;
}

.search-component .lower-part .keywords button {
  width: auto;
  padding: 5px 2rem;
  border-color: #117986;
  background-color: #fff;
  color: #117986;
  border-radius: 40px;
  margin: 10px 5px;
  font-size: 14px;
}

.search-component .lower-part .keywords button:hover {
  color: #fff !important;
  background-color: #117986 !important;
}

.call-to-action {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.call-to-action .text {
  margin-top: 20px;
}

.search-btn {
  background: #df4e24;
  color: #fff;
  font-size: 16px;
  margin: 0 0 0 10.8px;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  text-align: left;
  color: #fff;
  padding: 10px 32.9px;
  border-radius: 29px;
  box-shadow: inset 0 -3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #df4e24;
}

.search-btn:hover {
  color: #fff;
  background: #e23100;
}

.search-btn svg {
  font-weight: bold;
  font-size: 23px;
}

.call-to-action .star {
  font-size: 150px;
}

.already-added {
  background-color: rgba(255, 23, 23, 0.7);
  width: 50%;
  margin: 0 auto;
  padding: 5px;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 5px;
}

@media only screen and (max-width: 1950px) {
  .search-wrapper {
    width: 80%;
  }

  .search-btn {
    font-size: 17px;
    padding: 10px 22.9px;
  }

  .search-btn svg {
    font-weight: bold;
    font-size: 23px;
  }
}

@media only screen and (max-width: 1650px) {
  .search-wrapper {
    width: 82%;
  }

  .search-btn {
    font-size: 19px;
    padding: 10px 22.9px;
  }

  .search-btn svg {
    font-weight: bold;
    font-size: 23px;
  }

  .title {
    font-size: 80px;
  }

  .subtitle {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1400px) {
  .search-wrapper {
    width: 86%;
  }

  .search-btn {
    font-size: 17px;
    padding: 10px 22.9px;
  }

  .search-btn svg {
    font-weight: bold;
    font-size: 23px;
  }
}

@media only screen and (max-width: 1000px) {
  .search-wrapper {
    padding: 25% 0 10%;
    margin: 0 auto;
    width: 90%;
  }

  .search-component .upper-part .types div {
    font-size: 16px;
    width: 40%;
  }

  .title {
    font-size: 70px;
  }

  .subtitle {
    font-size: 22px;
  }
}

.body-select input {
  font-size: 12px;
}

#body-type:focus,
#make:focus,
#model:focus,
#keywords:focus {
  background-color: white;
}

@media (max-width:991px) {
  .search-component .lower-part .main .select-filter {
    width: 25%;
  }

  .search-component .lower-part {
    padding: 20px 0px 40px 0;
  }
}

@media only screen and (max-width: 768px) {
  .search-component .lower-part .main .select-filter {
    width: 50%;
  }

  .search-component .css-dq503m-MuiFormControl-root {
    width: 100%;
    margin: auto;
    padding: 0 20px;
  }

  .search-component .css-tfl8ho-MuiFormLabel-root-MuiInputLabel-root {
    left: 20px;
  }

  .title {
    font-size: 60px;
  }

  .subtitle {
    font-size: 20px;
  }

  .search-wrapper {
    padding: 35% 0 10%;
    margin: 0 auto;
    width: 90%;
  }

  .search-component .upper-part {
    width: 100%;
  }

  .search-component .lower-part {
    border-top-right-radius: 0px;
    padding: 10px 0px;
  }

  .call-to-action {
    display: block;
    margin-top: 20px;
  }

  .call-to-action .star {
    font-size: 100px;
  }

  .call-to-action .star:last-child {
    display: none;
  }

  .call-to-action .text {
    margin-top: 10px;
  }

  .call-to-action .text h2 {
    font-size: 20px;
  }

  .call-to-action .text div {
    font-size: 14px;
  }

  .search-btn {
    font-size: 14px;
  }

  .search-component .upper-part .types div {
    font-size: 16px;
    width: 50%;
  }

  .already-added {
    width: 80%;
  }

  .search-component .lower-part .keywords button {
    padding: 5px 1rem;
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .search-component .css-dq503m-MuiFormControl-root {
    padding: 0 11px;
  }

  .search-component .css-tfl8ho-MuiFormLabel-root-MuiInputLabel-root {
    left: 11px;
  }

  .search-wrapper {
    padding: 45% 0 10%;
    margin: 0 auto;
    width: 90%;

    .already-added {
      width: 80%;
    }
  }
}

@media only screen and (max-height: 750px) {
  .title {
    font-size: 50px;
  }

  .subtitle {
    font-size: 18px;
  }

  .search-wrapper {
    padding: 35% 0 0;
    margin: 0 auto;
    width: 90%;
  }

  .search-component .upper-part {
    width: 100%;
  }

  .search-component .lower-part {
    border-top-right-radius: 0px;
    padding: 5px 0px;
  }

  .call-to-action {
    display: block;
    margin-top: 10px;
  }

  .call-to-action .star {
    font-size: 100px;
  }

  .call-to-action .star:last-child {
    display: none;
  }

  .call-to-action .text {
    margin-top: 10px;
  }

  .call-to-action .text h2 {
    font-size: 20px;
  }

  .call-to-action .text div {
    font-size: 14px;
  }

  .search-component .upper-part .types div {
    font-size: 15px;
  }
}

@media (max-width:575px) {
  .product-card-body-carousel {
    .right {
      padding: 20px 0 !important;
    }
  }

  .product-card-wav-detail {
    ul {
      li {
        margin: 5px 0px !important;
      }
    }
  }

  .product-card-wav-features {
    .product-card-wav-feature {
      margin: 5px 0px !important;
      width: 100% !important;
    }
  }
}