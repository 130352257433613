#cancel-ads {
  min-height: 100vh;
  margin: 3rem 0;
  padding: 0;
  background-color: #f2f3f3;
  font-family: "Montserrat", "Open Sans", sans-serif;
}

#cancel-ads .cancel-ads-header {
  background-image: url("/public/assets/newheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 30vh;
  border-bottom: 3px solid rgb(233, 68, 3);
}
#cancel-ads .cancel-ads-header .wrapper {
  min-height: 30vh;
  background-color: rgba(1, 34, 49, 0.6);
  width: 100%;
}
#cancel-ads .cancel-ads-header .wrapper .text-wrapper {
  width: 60%;
  margin: 0 auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}
#cancel-ads .cancel-ads-header .wrapper .text-wrapper .subtitle {
  font-size: 15px;
  color: rgb(223, 223, 223);
}

#cancel-ads .advertise {
  width: 30%;
  margin: 2rem auto;
  text-align: start;
}
#cancel-ads .advertise div {
  margin: 2rem 0px;
  font-size: 14px;
}
#cancel-ads .advertise ol {
  margin: 0px 2rem;
}

#cancel-ads .contact-form {
  width: 30%;
  background-color: #fff;
  margin: 0px auto 2rem;
  padding: 2rem;
  text-align: start;
  border-radius: 5px;
  border-bottom: 3px solid #949495;
  -webkit-box-shadow: 0px 1px 11px -3px #bababb;
  box-shadow: 0px 2px 20px -3px #949494;
}

#cancel-ads .contact-form input {
  width: 80%;
}
#cancel-ads .contact-form .form-check input:last-child {
  width: 100%;
}
#cancel-ads .contact-form label {
  font-weight: bold;
  font-size: 1.3rem;
}
#cancel-ads .contact-form .form-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 1rem 0px;
}
@media only screen and (max-width: 1200px) {
  #cancel-ads .advertise {
    width: 50%;
  }
  #cancel-ads .contact-form {
    width: 50%;
  }
}
@media only screen and (max-width: 1000px) {
  #cancel-ads .cancel-ads-header .wrapper .text-wrapper {
    width: 90%;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5rem;
  }
  #cancel-ads .cancel-ads-header h1 {
    font-size: 23px;
    padding-top: 3rem;
  }
  #cancel-ads .advertise {
    width: 80%;
    margin: 2rem auto;
    text-align: start;
  }
  #cancel-ads .contact-form {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  #cancel-ads .cancel-ads-header .wrapper .text-wrapper {
    width: 96%;
  }
  #cancel-ads .advertise {
    width: 90%;
  }
  #cancel-ads .cancel-ads-header h1 {
    font-size: 20px;
  }
  #cancel-ads .contact-form {
    width: 90%;
  }
}
