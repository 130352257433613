#help-and-advice-main {
  margin: 8rem 0 0px;
  padding: 0;
  background-color: #f2f3f3;
  min-height: 100vh;
  color: #000;
  font-family: "Montserrat", "Open Sans", sans-serif;
}

/* HEADER */

#help-and-advice-main .help-and-advice-header {
  background-image: url("https://www.alliedmobility.com/wp-content/uploads/2022/02/Wheelchair-Accessible-Vehicles-For-Sale.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 46vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#help-and-advice-main .help-and-advice-header h2 {
  font-weight: bold;
  font-size: 2.5rem;
}

#help-and-advice-main .help-and-advice-header .minus-icons span svg {
  color: rgb(255, 153, 0);
  font-size: 40px;
}

#help-and-advice-main .help-and-advice-header .subtitle {
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

#help-and-advice-main .help-and-advice-header .subtitle div {
  text-align: center;
  font-size: 16px;
  color: #ccc;
  font-weight: bold;
}

/* BEST DEALS  */
#help-and-advice-main .help-and-advice-best-deals,
.help-and-advice-wheelchair-accessible .content {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  padding: 10px 0px;
  min-height: 50vh;
}

#help-and-advice-main .help-and-advice-best-deals .text {
  padding: 40px 0px 20px;
  margin-left: 4rem;
}

#help-and-advice-main .help-and-advice-best-deals .image {
  padding: 20px 0px 20px;
}

#help-and-advice-main .help-and-advice-best-deals div.text h1 {
  color: black;
  font-size: 25px;
  margin: 0px 0px 20px;
  font-weight: bold;
}

#help-and-advice-main .help-and-advice-best-deals div.text .subtitle {
  font-size: 14px;
  margin: 0px 0px 20px;
  font-weight: bold;
  color: #153e4d;
}

#help-and-advice-main .help-and-advice-best-deals div.text p {
  font-size: 14px;
  text-align: start;
  margin: 0px 0px 15px;
  color: #153e4d;
  font-weight: 400;
}

#help-and-advice-main .help-and-advice-best-deals div.text .footer {
  text-align: start;
  color: #333;
  font-size: 13px;
}

#help-and-advice-main .help-and-advice-best-deals img {
  max-width: 500px;
  width: fit-content;
  /* max-height: 500px; */
}

/* WHEELCHAIR ACCESSIBLE */
#help-and-advice-main .help-and-advice-wheelchair-accessible {
  background-color: #153e4d;
  color: #fff;
}

#help-and-advice-main .help-and-advice-wheelchair-accessible .content {
  width: 80%;
  margin: 0 auto;
  text-align: start;
}

#help-and-advice-main .help-and-advice-wheelchair-accessible .content h1 {
  font-size: 30px;
  font-weight: bold;
}

#help-and-advice-main .help-and-advice-wheelchair-accessible .content p {
  font-size: 14px;
}

/* KINDS */
#help-and-advice-main .help-and-advice-wheelchair-kinds {
  margin-top: 6rem;
  min-height: 60vh;
}

#help-and-advice-main .help-and-advice-wheelchair-kinds h2 {
  color: #232628;
  font-size: 26px;
}

#help-and-advice-main .help-and-advice-wheelchair-kinds .subtitle {
  font-weight: bold;
  color: #153e4d;
  font-size: 14px;
  text-align: center;
  margin: 30px auto;
}

#help-and-advice-main .help-and-advice-wheelchair-kinds .car-kind {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 5rem;
}

#help-and-advice-main .help-and-advice-wheelchair-kinds .car-img {
  margin-bottom: 40px;
}

#help-and-advice-main .help-and-advice-wheelchair-kinds .description {
  text-align: start;
}

#help-and-advice-main .help-and-advice-wheelchair-kinds .car-img svg {
  font-size: 200px;
  color: #153e4d;
}

/* SIZE */

#help-and-advice-main .help-and-advice-size {
  min-height: 50vh;
  background-image: url("https://s1.cdn.autoevolution.com/images/gallery/RENAULTKangoo-1946_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}

#help-and-advice-main .help-and-advice-size .content {
  z-index: 100000;
  min-height: 50vh;
  font-weight: bold;
  background-color: rgba(24, 37, 156, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
}

#help-and-advice-main .help-and-advice-size .content h1 {
  width: 50%;
  margin: 0 auto;
  margin: 20px 0px;
}

#help-and-advice-main .help-and-advice-size .content p {
  width: 50%;
  margin: 0 auto;
  margin: 10px 0px 20px;
}

/* DECISIONS  */
#help-and-advice-main .help-and-advice-decisions {
  min-height: 50vh;
}

#help-and-advice-main .help-and-advice-decisions h2 {
  font-size: 26px;
  color: #232628;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

#help-and-advice-main .help-and-advice-decisions .subtitle {
  font-size: 16px;
  color: #133440;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

#help-and-advice-main .help-and-advice-decisions .car-type {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: start;
}

#help-and-advice-main .help-and-advice-decisions .car-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-top: 3rem;
}

#help-and-advice-main .help-and-advice-decisions .car-img img {
  width: 300px;
  height: 150px;
}

/* #help-and-advice-main .help-and-advice-decisions .car-img img.wheelchair {
  width: 100%;
} */
#help-and-advice-main .help-and-advice-decisions .car-card {
  /* background-color: #00badb; */
  padding: 2rem;
  text-align: start;
  font-size: 14px;
  /* height: 350px; */
  border-radius: 10px;
  margin-top: 3rem;
}

/* #help-and-advice-main .help-and-advice-decisions .smaller .car-card {
  height: 250px;
} */
#help-and-advice-main .help-and-advice-decisions .car-card h3 {
  text-align: start;
  font-size: 24px;
}

#help-and-advice-main .help-and-advice-decisions .headroom-card {
  background-color: #00badb;
  padding: 2rem;
  margin-bottom: 3rem;
  border-radius: 10px;
}

#help-and-advice-main .help-and-advice-decisions .headroom-card div {
  margin: 10px auto;
}

#help-and-advice-main .help-and-advice-decisions .headroom .sizes,
.measurements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#help-and-advice-main .help-and-advice-decisions .headroom .sizes,
.measurements li {
  list-style: none;
  font-weight: bold;
  text-align: start;
}

/* OTHERS  */
#help-and-advice-main .help-and-advice-others {
  background-color: #dde0e6;
  padding: 2rem 0;
}

#help-and-advice-main .help-and-advice-others h1 {
  font-size: 30px;
  color: #232628;
}

#help-and-advice-main .help-and-advice-others .minus-icons span {
  font-size: 40px;
  color: rgb(255, 153, 0);
}

#help-and-advice-main .help-and-advice-others .row .col-md-6 {
  display: flex;
}

#help-and-advice-main .help-and-advice-others .row .col-md-6 .icon svg {
  font-size: 70px;
  margin-right: 40px;
  color: red;
}

#help-and-advice-main .help-and-advice-others .row .col-md-6 .text {
  font-size: 14px;
  color: #000;
  text-align: start;
}

#help-and-advice-main .help-and-advice-others .row .col-md-6 .text h5 {
  font-weight: bold;
  color: #232628;
  font-size: 14px;
}

#help-and-advice-main .help-and-advice-others a {
  /* text-decoration: none; */
  color: #000;
}

#help-and-advice-main .help-and-advice-others a:hover {
  color: #00badb;
}

/* COLOR CARDS */
#help-and-advice-main .help-and-advice-color-cards {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMqZbMH3mGOapooeSXIp7upgY18JHhsse2sA&usqp=CAU");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 50vh;
}

#help-and-advice-main .help-and-advice-color-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

#help-and-advice-main .help-and-advice-color-cards .yellow-card {
  background-color: rgba(240, 158, 6, 0.8);
  color: #74500c;
  padding: 50px 40px;
  text-align: start;
  width: 47%;
  margin-right: 30px;
  border-radius: 10px;
}

#help-and-advice-main .help-and-advice-color-cards .red-card {
  background-color: rgba(255, 7, 7, 0.8);
  color: #74500c;
  padding: 50px 40px;
  text-align: start;
  width: 45%;
  border-radius: 10px;
}

#help-and-advice-main .help-and-advice-color-cards .yellow-card h2,
.red-card h2 {
  font-size: 30px;
  width: 80%;
}

#help-and-advice-main .help-and-advice-color-cards .yellow-card h2 span,
.red-card h2 span {
  color: #fff;
}

#help-and-advice-main .help-and-advice-color-cards .yellow-card p,
.red-card p {
  color: #232628;
}

@media only screen and (max-width: 1000px) {
  #help-and-advice-main {
    margin: 6rem 0 0px;
  }

  #help-and-advice-main .help-and-advice-best-deals .text,
  #help-and-advice-main .help-and-advice-wheelchair-accessible .content .text {
    padding: 40px 0px 20px;
    margin-left: 0px;
  }

  #help-and-advice-main .help-and-advice-wheelchair-accessible .content {
    width: 96%;
    margin: 0 auto;
    text-align: start;
  }

  #help-and-advice-main .help-and-advice-size .content h1,
  #help-and-advice-main .help-and-advice-size .content p {
    width: 80%;
  }
}

@media (max-width:991px) {
  #help-and-advice-main .help-and-advice-decisions .car-img {
    width: 42%;
  }

  #help-and-advice-main .help-and-advice-decisions .car-card {
    height: auto !important;
  }

  #help-and-advice-main .help-and-advice-decisions h2,
  #help-and-advice-main .help-and-advice-decisions .subtitle {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {

  #help-and-advice-main .help-and-advice-best-deals,
  .help-and-advice-wheelchair-accessible .content {
    padding: 0px 10px;
    display: block;
    margin: 10px;
  }

  #help-and-advice-main .help-and-advice-best-deals .text {
    padding: 10px 0px 20px;
    margin-left: 0px;
  }

  .help-and-advice-best-deals div.text h1 {
    font-size: 25px;
  }

  .help-and-advice-best-deals div.text p,
  .help-and-advice-best-deals div.text .subtitle,
  .help-and-advice-best-deals div.text .footer {
    padding: 0px 20px;
  }

  .help-and-advice-best-deals img {
    max-width: 400px;
    width: fit-content;
  }

  #help-and-advice-main .help-and-advice-size .content h1,
  #help-and-advice-main .help-and-advice-size .content p {
    width: 90%;
  }

  #help-and-advice-main .help-and-advice-size .content h1 {
    font-size: 25px;
    text-align: center;
  }

  #help-and-advice-main .help-and-advice-decisions .car-type {
    display: block;
  }

  #help-and-advice-main .help-and-advice-decisions .car-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  #help-and-advice-main .help-and-advice-decisions .car-card {
    height: 300px;
  }

  #help-and-advice-main .help-and-advice-decisions .smaller .car-card {
    height: 200px;
  }

  #help-and-advice-main .help-and-advice-color-cards .yellow-card {
    width: 97%;
    margin: 40px auto;
  }

  #help-and-advice-main .help-and-advice-color-cards .red-card {
    width: 97%;
    margin: 40px auto;
  }

  #help-and-advice-main .help-and-advice-color-cards {
    margin-top: 40px;
  }

  #help-and-advice-main .help-and-advice-wheelchair-accessible .content h1 {
    font-size: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .help-and-advice-best-deals .image {
    max-width: 100%;
    margin: 0 auto;
    /* width: fit-content; */
    /* max-height: 500px; */
  }

  .help-and-advice-best-deals .image img {
    width: 250px;
    padding: 3rem;
  }

  #help-and-advice-main .help-and-advice-others .row .col-md-6 .icon svg {
    font-size: 42px;
    margin-right: 15px;
  }

  #help-and-advice-main .help-and-advice-decisions .car-img img {
    width: 100%;
    height: auto;
  }

  #help-and-advice-main .help-and-advice-decisions h2,
  #help-and-advice-main .help-and-advice-others h1,
  #help-and-advice-main .help-and-advice-color-cards .yellow-card h2,
  .red-card h2 {
    font-size: 20px;
  }

  #help-and-advice-main .help-and-advice-color-cards .yellow-card h2,
  .red-card h2 {
    width: 100%;
  }

  #help-and-advice-main .help-and-advice-color-cards .red-card,
  #help-and-advice-main .help-and-advice-color-cards .yellow-card {
    margin: 15px auto 13px;
  }

  #help-and-advice-main .help-and-advice-header h2 {
    font-size: 2rem;
  }
}